import React from 'react';
import { CategoryTextContainer, CategoryTitleWrapper, CategoryExcerpt } from './style';

const CategoryText = (props) => {
	return (
		<CategoryTextContainer>
			<CategoryTitleWrapper>
				<h1  dangerouslySetInnerHTML={{ __html: props.title }} />
				<h2>inspiration</h2>
			</CategoryTitleWrapper>
			<CategoryExcerpt>
				{props.description ? props.description : `A selection of the best visual compositions combining typography, visual elements, and page layout
				techniques.`}
			</CategoryExcerpt>

			
		</CategoryTextContainer>
	);
};

export default CategoryText;
