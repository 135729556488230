import React from "react"

import { AdvertisementContainer } from "./style.js"

const AdvertisementWrapper = ({
  order,
  children,
  horizontal,
  double,
  square,
  homeSquare,
  desktop,
  id,
  articleHorizontal,
  articleSquare,
  articleVertical,
  editorialVertical,
  editorialSquare,
  className,
  onClick,
  hide,
}) => {
  return (
    <AdvertisementContainer
      id={id}
      onClick={onClick}
      order={order}
      desktop={desktop}
      articleSquare={articleSquare}
      articleVertical={articleVertical}
      editorialVertical={editorialVertical}
      editorialSquare={editorialSquare}
      articleHorizontal={articleHorizontal}
      homeSquare={homeSquare}
      horizontal={horizontal}
      double={double}
      square={square}
      className={`advertisement ${className}`}
      hide={hide}
    >
      {children}
    </AdvertisementContainer>
  )
}

AdvertisementWrapper.defaultProps = {
  className: "",
}

export default AdvertisementWrapper
