import React from 'react';
import axios from 'axios';
import { SignUpContainer, SupportiveText, Form, SubmitButton, Sent, ErrorMessage } from './style';
import { Formik } from 'formik';
import Success from '../../images/success-tick.png';
import { ReactComponent as ErrorIcon } from '../../images/invalid-email.svg';
const SignUp = ({ white, article }) => {
	return (
		<SignUpContainer article={article} white={white}>
			{white ? null : <SupportiveText>Want to see your work here?</SupportiveText>}

			<Formik
				initialValues={{ email: '' }}
				validateOnChange={false}
				validate={(values) => {
					let errors = {};

					if (!values.email) {
						errors.email = 'Please enter a valid email address';
						console.log('error1');
					} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
						console.log('error2');
						errors.email = 'Please enter a valid email address';
					}

					return errors;
				}}
				onSubmit={(values, { setSubmitting }) => {
					setTimeout(() => {
						setSubmitting(true);
					}, 400);

					const { email } = values;
					console.log(email);

					axios
						.post('/.netlify/functions/signUpHandler', {
							email: email,
							tags: white || article ? [] : [ 'community signup' ]
						})
						.then(function(response) {
							console.log(response);
						})
						.catch(function(error) {
							console.log(error);
						});
				}}
			>
				{({ values, errors, handleChange, handleSubmit, isSubmitting, validateForm }) => (
					<Form showError={errors.email} onSubmit={handleSubmit} disabled={isSubmitting} white={white}>
						<Sent disabled={isSubmitting} white={white}>
							<img src={Success} alt="" />
							<p>
								{white ? 'Thanks' : 'Thank you'} for subscribing!<br /> We'll be in touch soon.
							</p>
						</Sent>

						<input
							type="text"
							name="email"
							id="email"
							disabled={isSubmitting}
							value={isSubmitting ? '' : values.email}
							onChange={handleChange}
							spellCheck={false}
							placeholder={
								isSubmitting ? '' : white ? 'Your email address...' : 'Enter your email address...'
							}
						/>

						<SubmitButton
							type="submit"
							disabled={isSubmitting}
							onSubmit={handleSubmit}
							onClick={() => {
								validateForm();
							}}
						>
							{white ? 'Hook me up' : 'Join our community'}
						</SubmitButton>
						<ErrorMessage showError={errors.email} name="email" disabled={isSubmitting} white={white}>
							<ErrorIcon />
							<p>{errors.email}</p>
						</ErrorMessage>
					</Form>
				)}
			</Formik>
		</SignUpContainer>
	);
};

export default SignUp;
