import React from 'react';
import { NavListItem, NewTag } from './style';
import { Link } from 'gatsby';

const NavBar = ({ newTag, label, path, no__desktop }) => {
	return (
		<NavListItem no__desktop={no__desktop}>
			<Link to={path ? path : '/'}>
				<span className="label">
					{label}

					{newTag && <NewTag>NEW!</NewTag>}
				</span>
			</Link>
		</NavListItem>
	);
};

export default NavBar;
