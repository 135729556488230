import React from "react"
import { LatestPostsData } from "../../hooks/latestPosts"
import { HomeGridContainer } from "./style.js"
import { Button } from "../Button"
import { Teaser } from "../Teaser"

import { SliderGrid, SmallGrid } from "./index.js"
import { AdvertisementWrapper } from "../AdvertisementWrapper"
import { GoogleAd } from "../GoogleAd"

const HomeGrid = ({ pinnedEditorial }) => {
  const featuredPosts = LatestPostsData()
  const teasers = featuredPosts.posts.nodes.slice(
    pinnedEditorial.editorial ? 2 : 3,
    18
  )

  return (
    <HomeGridContainer>
      <SliderGrid>
        {teasers.slice(0, 2).map((teaser, index) => {
          return (
            <Teaser
              key={index}
              title={teaser.title}
              excerpt={teaser.excerpt}
              categories={teaser.categories.nodes.slice(0, 2)}
              link={teaser.link}
              img={teaser.featuredImage}
            />
          )
        })}

        <AdvertisementWrapper double>
          <GoogleAd
            className="vertical"
            client="ca-pub-4461681428787239"
            slots={["1384776853"]}
          />
          <GoogleAd
            className="square"
            client="ca-pub-4461681428787239"
            slots={["1775659092"]}
          />
        </AdvertisementWrapper>
        {teasers.slice(2, 7).map((teaser, index) => {
          return (
            <Teaser
              key={index}
              title={teaser.title}
              excerpt={teaser.excerpt}
              categories={teaser.categories.nodes}
              link={teaser.link}
              img={teaser.featuredImage}
              color={teaser.colourFeatureImage}
            />
          )
        })}
      </SliderGrid>
      <AdvertisementWrapper square homeSquare>
        <GoogleAd
          className="square"
          client="ca-pub-4461681428787239"
          slots={["7020431665"]}
        />
      </AdvertisementWrapper>
      <SmallGrid>
        {teasers.slice(7, 15).map((teaser, index) => {
          return (
            <Teaser
              key={index}
              small
              title={teaser.title}
              link={teaser.link}
              img={teaser.featuredImage}
              color={teaser.colourFeatureImage}
            />
          )
        })}
      </SmallGrid>
      <Button label="Discover more" full path="/search/" />
    </HomeGridContainer>
  )
}

export default HomeGrid
