import React, { Component } from "react"
import Card from "./card.js"

import {
  CarouselWidthContainer,
  ComponentContainer,
  CarouselContainer,
  CTAWrapper,
  PageWidthContainer,
} from "./style.js"
import { Button } from "../Button/index.js"
import CarouselTitle from "./carouselTitle.js"

class Carousel extends Component {
  constructor(props) {
    super(props)

    this.carouselRef = React.createRef()
    this.cardContainer = React.createRef()
    this.progressBarRef = React.createRef()
    this.cardRef = React.createRef()

    this.state = {
      carouselPosition: 0,
      isMobile: false,
      progressPosition: 0,
      barWidth: 25,
      nextDisabled: false,
      prevDisabled: true,
      cardFlexBasis: 0,
      carouselVisible: false,
      imagePosition: 0,
    }

    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.positionReset = this.positionReset.bind(this)
    this.tabletTrigger = this.tabletTrigger.bind(this)
    this.desktopTrigger = this.desktopTrigger.bind(this)

    this.setProgressBar = this.setProgressBar.bind(this)
    this.handleMobileScroll = this.handleMobileScroll.bind(this)
  }

  _mounted = false

  componentDidMount() {
    this._mounted = true

    // Initialize the media query
    // this.mediaQueryMobile = window.matchMedia('(max-width: 768px)');
    this.mediaQueryTablet = window.matchMedia("(max-width: 1024px)")
    this.mediaQueryDesktop = window.matchMedia("(min-width: 1024px)")
    this.mediaQueryScroll = window.matchMedia("(max-width: 1280px)")

    // Add a listen event to the media query
    this.mediaQueryScroll.addListener(this.positionReset)
    this.mediaQueryTablet.addListener(this.tabletTrigger)
    this.mediaQueryDesktop.addListener(this.desktopTrigger)

    this.setState({
      carouselVisible: true,
    })

    // On load
    this.positionReset(this.mediaQueryScroll)
    this.setProgressBar(this.mediaQueryScroll)

    if (this.mediaQueryScroll.matches) {
      this.setState({
        progressPosition: 0,
      })
    } else {
      this.setState({
        progressPosition: 0,
      })

      setTimeout(() => {
        this.setProgressBar()
      }, 50)
    }

    if (this.mediaQueryTablet.matches) {
      this.setState(
        {
          cardsToShow: this.props.header ? 1.4 : 3,
          carouselPosition: this.props.end ? this.props.posts.length - 3 : 0,
          prevDisabled: this.props.end ? false : true,
          nextDisabled: this.props.end ? true : false,
        },
        () => this.setBasis(this.state.cardsToShow)
      )
    }

    if (this.mediaQueryDesktop.matches) {
      this.setState(
        {
          cardsToShow: this.props.header ? 1.4 : 4,
          carouselPosition: this.props.end ? this.props.posts.length - 4 : 0,
          prevDisabled: this.props.end ? false : true,
          nextDisabled: this.props.end ? true : false,
        },
        () => this.setBasis(this.state.cardsToShow)
      )
    }

    // console.log(this.state.isMobile)
  }

  componentWillUnmount() {
    this._mounted = false
    this.mediaQueryScroll.removeListener(this.positionReset)
    this.mediaQueryTablet.removeListener(this.tabletTrigger)
    this.mediaQueryDesktop.removeListener(this.desktopTrigger)

    clearTimeout(this.makeMobileId)
  }

  positionReset(mediaQuery) {
    const el = this.carouselRef.current

    if (!this._mounted) {
      return false
    } else {
      el.scrollLeft = 0
    }

    this.setState({
      carouselPosition: 0,
      progressPosition: 0,
      imagePosition: 0,
      nextDisabled: false,
      prevDisabled: true,
    })

    if (mediaQuery.matches) {
      this.makeMobile()
    } else {
      clearTimeout(this.makeMobileId)
      this.setState({
        isMobile: mediaQuery.matches,
        progressPosition: 0,
      })
    }
  }

  tabletTrigger() {
    this.setState(
      {
        cardsToShow: this.props.header ? 1.4 : 3,
      },
      () => this.setBasis(this.state.cardsToShow)
    )
  }

  desktopTrigger() {
    this.setState(
      {
        cardsToShow: this.props.header ? 1.4 : 4,
      },
      () => this.setBasis(this.state.cardsToShow)
    )
  }

  makeMobile = () => {
    // this.makeMobileId = setTimeout(() => {
    this.setState({ isMobile: true })
    // }, 1000);
  }

  setBasis = cardsToShow => {
    this.setState({ cardFlexBasis: 100 / cardsToShow })
  }

  next() {
    //updating carouselPosition
    const currentState = this.state.carouselPosition

    this.setState({ carouselPosition: currentState + 1 }, () =>
      this.setProgressBar()
    )

    //moving images left
    const currentImagePosition = this.state.imagePosition

    this.setState({ imagePosition: currentImagePosition - 6 })

    //disabled btn
    if (
      this.props.header
        ? this.state.carouselPosition === 10
        : this.state.carouselPosition ===
          this.props.posts.length - this.state.cardsToShow - 1
    ) {
      this.setState({ nextDisabled: true })

      if (this.props.posts.length === 4) {
        this.setState({ nextDisabled: true, prevDisabled: false })
      }
    } else {
      this.setState({
        nextDisabled: false,
        prevDisabled: false,
      })
    }

    console.log(this.state.cardsToShow)
  }

  previous() {
    const currentState = this.state.carouselPosition
    this.setState({ carouselPosition: currentState - 1 }, () =>
      this.setProgressBar()
    )

    //moving images right

    const currentImagePosition = this.state.imagePosition

    this.setState({ imagePosition: currentImagePosition + 6 })

    if (this.state.carouselPosition === 1) {
      this.setState({
        prevDisabled: true,
      })

      if (this.props.posts.length === 4) {
        this.setState({ prevDisabled: true, nextDisabled: false })
      }
    } else {
      this.setState({
        prevDisabled: false,
        nextDisabled: false,
      })
    }
  }

  setProgressBar() {
    this.setState({
      progressPosition:
        this.state.carouselPosition *
        (300 / (this.props.posts.length - this.state.cardsToShow)),
    })
  }

  handleMobileScroll() {
    const el = this.carouselRef.current
    const scrollPercent = el.scrollLeft / (el.scrollWidth - el.clientWidth)

    this.setState({
      carouselPosition: 0,
      progressPosition:
        this.state.barWidth *
        this.props.posts.length *
        scrollPercent *
        (12 / this.props.posts.length),
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.resetTriggered !== prevProps.resetTriggered &&
      (this.state.carouselPosition || this.state.progressPosition) !== 0
    ) {
      this.positionReset(this.mediaQueryScroll)
    }
    return null
  }

  render() {
    return (
      <PageWidthContainer>
        <CarouselWidthContainer className="width-container">
          <ComponentContainer className="component-container">
            <CarouselTitle
              next={this.next}
              previous={this.previous}
              prevDisabled={this.state.prevDisabled}
              nextDisabled={this.state.nextDisabled}
              header={this.props.header}
              title={this.props.title}
            />
            <CarouselContainer
              style={{
                transform:
                  this.state.isMobile && !this.props.header
                    ? null
                    : `translateX( ${this.state.carouselPosition *
                        -this.state.cardFlexBasis}%)`,
                overflow: this.state.isMobile ? "auto" : "visible",
              }}
              ref={this.carouselRef}
              onScroll={this.handleMobileScroll}
              className="carousel-container"
              header={this.props.header}
            >
              {this.props.posts.map((post, index) => (
                <Card
                  ref={this.cardRef}
                  key={index}
                  isMobile={this.state.isMobile}
                  index={index}
                  title={post.title}
                  link={post.link}
                  color={post.colourFeatureImage}
                  img={post.featuredImage}
                  imagePosition={this.state.imagePosition}
                  cardFlexBasis={this.state.cardFlexBasis}
                  header={this.props.header}
                />
              ))}
            </CarouselContainer>

            {this.props.header ? null : (
              <CTAWrapper header={this.props.header}>
                <Button
                  full
                  path={this.props.ctaPath}
                  label={`View all ${this.props.title}`}
                />
              </CTAWrapper>
            )}
          </ComponentContainer>
        </CarouselWidthContainer>
      </PageWidthContainer>
    )
  }
}

export default Carousel
