import styled from 'styled-components';
import { device } from '../../utils/breakpoints.js';

export const FeaturedTeaserContainer = styled.div`
	position: relative;
	z-index: ${(props) => (props.first ? 1 : 0)};
	pointer-events: none;
	@media ${device.tablet} {
		padding: 40px 23px 0 23px;
		margin-bottom: -30px;
	}

	@media ${device.desktop} {
		padding: 0;
		margin-bottom: 0px;
		padding-top: 40px;
	}

	@media ${device.large} {
		padding-top: 50px;
	}

	:hover {
		.image-container {
			img {
				transform: scale(1.11) !important;
			}
		}

		.title {
			color: ${(props) => props.theme.colors.teal};
		}
	}
`;

export const BackgroundColor = styled.div`
	display: none;
	position: absolute;
	top: 0;
	left: ${(props) => (props.first ? '0' : 'auto')};
	right: ${(props) => (props.first ? 'auto' : '0')};
	width: 100%;
	height: 400px;
	z-index: -1;
	/* background: ${(props) => props.bgColor}; */
	background: black;

	pointer-events: none;
	overflow:hidden;
	@media ${device.tablet} {
		display: block;
	}

	@media ${device.large} {
		height: 420px;
	}

  img {
			transform: scale(1.5);
		filter: blur(12px);
  }

	>.gatsby-image-wrapper {
		height: 100%;
		width:100%;
		opacity: 0.7;

		img {
			transform: scale(1.5);
		filter: blur(12px);
		}
	}
`;

export const FeaturedTeaserWrapper = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	pointer-events: all;

	@media ${device.desktop} {
		max-width: 683px;
		margin: ${(props) => (props.first ? '0 0 0 auto' : null)};
		z-index: ${(props) => (props.first ? '1' : null)};
	}

	> a {
		width: 100%;
		::before {
			position: absolute;
			content: "";
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			/* z-index: 1; */
		}
	}
`;

export const ImageContainer = styled.div`
	position: relative;
	overflow: hidden;

	img {
		transform: scale(1) !important;
		transition: 469ms !important;
	}

	.gatsby-image-wrapper,
	img {
		width: 100%;
		height: 100%;
		margin: 0;
		object-fit: cover;
		display: block;
	}

	@media (min-width: 1024px) {
		height: 540px;
	}

	::before {
		content: "";
		background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.4) 1%, rgba(0, 0, 0, 0) 46%);
		z-index: 1;
		width: 100%;
		height: 300px;
		position: Absolute;
		display: ${(props) => props.second && 'none'};
		top: 0;
		left: 0;

		@media (min-width: 1024px) {
			display: block;
			height: 540px;
		}
	}
`;

export const InfoContainer = styled.div`
	background: #fff;
	padding: 16px 19px 0 19px;
	border-radius: 2px;
	position: relative;
	top: -30px;
	z-index: 2;
	width: calc(100% - 20px);
	pointer-events: none;
	margin: 0 auto;

	padding-top: 16px;
	padding-top: 16px;

	@media ${device.tablet} {
		top: -100px;
		width: calc(100% - 150px);
		padding: 32px 32px 0px 32px;
	}

	@media ${device.desktop} {
		top: -36px;
		width: ${(props) => (props.first ? 'calc(100% - 20px)' : 'calc(100% - 40px);')};
		width: calc(100% - 20px);
		border-radius: ${(props) => (props.first ? '2px 0 2px 2px' : '2px 2px 2px 0')};
		padding-bottom: 30px;
		padding-left: ${(props) => (props.first ? null : '62px')};
		padding-right: ${(props) => (props.first ? '62px' : null)};
		padding-top: 22px;

		margin: ${(props) => (props.first ? '0 0 0 auto' : '0 auto 0 0')};
		transform: ${(props) => (props.first ? 'translateX(20px)' : 'translateX(-20px)')};
	}

	@media ${device.large} {
		top: -69px;
		width: calc(100% - 40px);
		padding: 60px;
		padding-left: ${(props) => (props.first ? null : '80px')};
		padding-right: ${(props) => (props.first ? '80px' : null)};
		padding-top: 54px;
	}
`;

export const Categories = styled.div`
	/* display: inline-flex; */
	margin-bottom: 12px;
	z-index: 123023;
	pointer-events: none;
	@media ${device.tablet} {
		margin-bottom: 16px;
	}

	a {
		text-decoration: none;
		color: ${(props) => props.theme.colors.grey[2]};
		margin-right: 12px;
		text-transform: uppercase;
		font-size: 8px;
		line-height: 100%;
		pointer-events: all;
		font-weight: 600;

		@media ${device.tablet} {
			font-size: 10px;
		}

		:hover {
			color: ${(props) => props.theme.colors.teal};
			transition: 100ms;
		}
	}
`;

export const Title = styled.h3`
	line-height: 114%;
	font-size: 28px;
	margin-bottom: 14px;
	transition: 200ms;
	@media ${device.tablet} {
		font-size: 36px;
		margin-bottom: 20px;
	}

	@media ${device.desktop} {
		font-size: 32px;
	}

	@media ${device.big} {
		font-size: 36px;
	}
`;

export const Excerpt = styled.p`
	line-height: 150%;
	font-size: 16px;
	margin-bottom: 0px;
`;
