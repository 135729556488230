import React from 'react';
import AvenueLink from '../AvenueLink.js';

import { Link } from 'gatsby';
import {
	TeaserContainer,
	ImageContainer,
	ImageWrapper,
	Title,
	Date,
	Excerpt,
	Categories,
	ButtonContainer,
	Artist,
	FullWidthContainer,
	ReadTag,
} from './style.js';
import { Button } from '../Button';
import { ResponsiveGatsbyImage } from '../ResponsiveGatsbyImage';

const Teaser = ({
	title,
	categories,
	excerpt,
	small,
	secondColumn,
	little,
	editorial,
	setRef,
	community,
	artist,
	product,
	carousel,
	ctaLabel,
	img,
	date,
	slug,
	color,
	link
}) => {
	const noHover = !link && !slug;
	const rgb = color && JSON.parse(color).join();

	return (

		<FullWidthContainer className="teaser"editorial={editorial}>
			<TeaserContainer
				secondColumn={secondColumn}
				noHover={noHover}
				className="teaserContainer"
				small={small}
				little={little}
				editorial={editorial}
				product={product}
				community={community}
			>
				<ImageContainer editorial={editorial} product={product} community={community} little={little}>
					<ImageWrapper little={little}>
						{img ? (
							<ResponsiveGatsbyImage
								style={{ backgroundColor: color ? `rgb(${rgb})` : null }}
								setRef={setRef}
								className="image"
								src={img.sourceUrl}
								srcSet={img.srcSet}
								sizes={small ? `(min-width: 768px) 33vw, 50vw` : `(min-width: 1024px) 500px, 100vw`}
								height={img.mediaDetails ? img.mediaDetails.height : '988'}
								width={img.mediaDetails ? img.mediaDetails.width : '1400'}
							/>
						) : (
							<img
								src="https://res.cloudinary.com/avenue/image/upload/v1564980407/roundup-tile-feb-19-unicorn_ysma0h.jpg"
								alt=""
							/>
						)}
					</ImageWrapper>
				</ImageContainer>
				<Categories
					className="categories"
					small={small}
					product={product}
					community={community}
					editorial={editorial}
					little={little}
				>
					{categories &&
						categories.slice(0, 2).map((category, index) => (
							<Link key={index} to={`/category/${category.slug}/`}>
								<span dangerouslySetInnerHTML={{ __html: category.name }} />
							</Link>
						))}
				</Categories>
				{link ? <AvenueLink to={link} /> : slug ? <Link to={`/${slug}/`}> </Link> : null}
				<Title
					little={little}
					small={small}
					product={product}
					carousel={carousel}
					community={community}
					editorial={editorial}
					className="title"
					dangerouslySetInnerHTML={{ __html: title }}
				/>
				{editorial && <ReadTag className="readTag">Read it</ReadTag>}

				{date && <Date>{date}</Date>}
				{little ? null :
				<Excerpt
					editorial={editorial}
					small={small}
					product={product}
					carousel={carousel}
					community={community}
					little={little}
					className="excerpt"
					dangerouslySetInnerHTML={{ __html: excerpt }}
				/>
				}
				{ctaLabel && (
					<ButtonContainer>
						<Button label={ctaLabel} />
					</ButtonContainer>
				)}
				{community &&
				artist && (
					<Artist>
						Submitted by <span>{artist}</span>
					</Artist>
				)}
			</TeaserContainer>
		</FullWidthContainer>
	);
};

export default Teaser;
