import styled from 'styled-components';
import { device } from '../../utils/breakpoints';
import Img from 'gatsby-image';
export const EmailContainer = styled.div`
	margin: 24px auto;
	width: 100%;
	padding: 50px;
	position: relative;


	@media ${device.tablet} {
		padding: 100px 100px 130px 100px;
		margin: 58px auto;
	}

	@media ${device.desktop} {
		padding: 125px 200px 150px 200px;

	}

  @media ${device.large} {
    padding ${(props) => props.article && '0'};
    height ${(props) => props.article && '468px'};
    display:  ${(props) => props.article && 'flex'};
  flex-direction:${(props) => props.article && 'column'};
  align-items:${(props) => props.article && 'center'};
  justify-content: ${(props) => props.article && 'center'};

	}

	@media ${device.big} {
		margin: 68px auto;
		padding: 250px 200px 250px 200px;
    padding ${(props) => props.article && '0'};

	}

`;
export const BgImage = styled(Img)`
  height: 100%;
  width: 100%;
  margin: 0;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
`
export const EmailWrapper = styled.div`
	margin: 0 auto;
	max-width: ${(props) => props.theme.mainMaxWidth};

	@media ${device.large} {
		display: ${(props) => props.article && 'flex'};
		flex-direction: column;
		align-items: center;
	}

	@media ${device.big} {
		display: ${(props) => (props.article ? 'flex' : 'grid')};
		grid-template-columns: 1fr 1fr;
	}
`;

export const HeadingContainer = styled.div`
	margin-bottom: 27px;
	@media ${device.tablet} {
		margin-bottom: 60px;
	}

	@media ${device.desktop} {
		margin-bottom: 50px;
	}

	@media ${device.large} {
		display: ${(props) => props.article && 'flex'};
		flex-direction: column;
		align-items: center;
		margin-bottom: ${(props) => props.article && '30px'};
		margin-top: ${(props) => props.article && '-20px'};

	}

	@media ${device.big} {
		margin-bottom: 13px;
		margin-bottom: ${(props) => props.article && '30px'};
    
	}

	br {
		@media ${device.large} {
			display: ${(props) => props.article && 'none'};
		}
	}

	h2 {
		font-size: 42px;
		color: #fff;
		line-height: 95%;

		@media ${device.tablet} {
			font-size: 84px;
		}

		@media ${device.desktop} {
			font-size: 96px;
		}

		@media ${device.large} {
			font-size: ${(props) => (props.article ? '72px' : '96px')};
			margin-bottom: ${(props) => props.article && '20px'};
		}
	}

	h4 {
		font-size: 16px;
		color: #fff;
		display: inline-block;

		@media ${device.tablet} {
			font-size: 20px;
		}

		@media ${device.desktop} {
			font-size: 22px;
		}

		@media ${device.large} {
      font-size: ${(props) => (props.article ? '20px' : '22px')};
		}
	}
`;

export const Artist = styled.div`
	font-size: 12px;
	opacity: 0.7;
	font-weight: 600;
	color: #fff;
	margin-top: 40px;

	@media ${device.tablet} {
		margin-top: 0px;
		position: absolute;
		bottom: 25px;
		right: 25px;
	}

	@media ${device.big} {
		max-width: 1200px;
		width: 100%;
		right: 50%;
		text-align: right;
		transform: translateX(50%);
	}

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

export const PrivacyText = styled.p`
margin-top:16px;
font-size: 11px;
color:#fff;
width: 100%;

@media ${device.tablet} {
margin-top: 16px;
font-size: 13px;

}


@media ${device.large} {
	margin-top: 27px;
width: ${props => props.article ? '574px' : '100%'};
font-size: 14px;

}

@media ${device.big} {
width: ${props => props.article ? '574px' : '70%'};

grid-column: 2 / span 1;
}

a {
	color: #fff;
	font-weight:600;
}
	
	
`;