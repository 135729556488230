import React, { Component } from 'react';
import { CardContainer } from './style.js';
import { Teaser } from '../Teaser';

class CarouselCard extends Component {
	constructor(props) {
		super(props);

		this.imgRef = React.createRef();


		this.state = {
			initialOffset: 0,
		};

		this.cardContainerRef = React.createRef();
		this.positionReset = this.positionReset.bind(this);
	}

	componentDidMount() {
		this.mediaQueryMobile = window.matchMedia('(max-width: 768px)');
		this.mediaQueryTablet = window.matchMedia('(max-width: 1024px)');
		this.mediaQueryDesktop = window.matchMedia('(min-width: 1024px)');


		this.mediaQueryMobile.addListener(this.positionReset);
		this.positionReset(this.mediaQueryMobile);
	}

	componentWillUnmount() {
		this.mediaQueryMobile.removeListener(this.positionReset);
	}

	positionReset(mediaQuery) {
		if (mediaQuery.matches) {
			this.setState({
				initialOffset: 0
			});
		} else {
			this.setState({
				initialOffset: this.props.index * 5
			});
		}
	}

	componentDidUpdate() {
		if (this.props.isMobile) {
			this.cardContainerRef.current.style.flex = `1 0 65% `;
		} else {
			// this.imageMove();
			this.setCardFlexBasis();
		}
	}

	setCardFlexBasis() {
		this.cardContainerRef.current.style.flex = `1 0 ${this.props.cardFlexBasis}% `;
	}

	imageMove() {
		// console.log(this.imgRef.current.imageRef.current.offsetParent)
		this.imgRef.current.imageRef.current.offsetParent.style.transform = `scale(1) translateX(${this.state.initialOffset + this.props.imagePosition}%)`;
	}

	render() {



		return (
			<CardContainer {...this.props} ref={this.cardContainerRef} className="card-container">
				<Teaser
					setRef={this.imgRef}
					carousel
					small
					title={this.props.title}
					excerpt="Canadian studio Haryco was responsible for the striking identity and packaging for Curata, a luxury brand producing high-end, sustainable beauty products."
					img={this.props.img}
					link={this.props.link}
					color={this.props.color}
				/>
			</CardContainer>
		);
	}
}

export default CarouselCard;
