import styled from 'styled-components';
import { Link } from 'gatsby';

import { device } from '../../utils/breakpoints';

export const Container = styled.div`
	margin: 20px auto;
	max-width: 1200px;
`;
export const FooterMenuIcons = styled.div`
	@media ${device.tablet} {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`;

export const Menu = styled.div`
	display: flex;
	justify-content: space-between;
	max-width: 330px;
	width: 100%;

	@media ${device.desktop} {
		max-width: 365px;
	}
`;

export const MenuItems = styled(Link)`
  font-weight: 600;
  font-size: 12px;
  color: ${(props) => props.theme.colors.grey[2]};
  letter-spacing: 0;
  line-height: 24px;
  text-decoration: none;
  margin-bottom: 30px;
  cursor: pointer;

  :hover {
    color: ${(props) => props.theme.colors.turquoise};
  }

  @media ${device.tablet} {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 0;
  }

  :hover {
    color: ${(props) => props.theme.colors.teal};
    transition: 80ms;
  }
`;

export const MenuItemsA = styled.a`
	font-weight: 600;
	font-size: 12px;
	color: ${(props) => props.theme.colors.grey[2]};
	letter-spacing: 0;
	line-height: 24px;
	text-decoration: none;
	margin-bottom: 30px;
	cursor: pointer;

	@media ${device.tablet} {
		font-weight: 600;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 24px;
		margin-bottom: 0;
	}

	:hover {
		color: ${(props) => props.theme.colors.teal};
		transition: 80ms;
	}
`;
export const Copyright = styled.p`
	font-size: 12px;
	color: ${(props) => props.theme.colors.grey[2]};
	letter-spacing: 0;
	line-height: 18px;
	margin-top: 30px;
	a {
		font-weight: 600;
		text-decoration: none;
		color: ${(props) => props.theme.colors.turquoise};
		:hover {
			opacity: 0.6;
			transition: 80ms;
		}
	}

	@media ${device.tablet} {
		margin-top: 55px;
	}
`;

export const RightContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 400px) {
		flex-direction: row;
		/* justify-content: space-between; */
		align-items: flex-end;
	}

	@media ${device.tablet} {
		margin-top: -18px;
	}
	a.avenue {
		:hover {
			opacity: 0.7;
			transition: 80ms;
		}
		svg {
			display: block;
		}
		margin-top: 30px;
		display: block;
		@media (min-width: 400px) {
			margin-top: 0;
			margin-left: 34px;
			position: relative;
			::before {
				content: '';
				position: absolute;
				left: -23px;
				top: 5px;
				height: 85%;
				width: 1px;
				background: #d7e1e2;
			}
		}

		@media ${device.tablet} {
			margin-left: 46px;
		}
	}
`;
