import styled from "styled-components"
import { device } from "../../utils/breakpoints"
export const FullWidthContainer = styled.div`
  width: 100%;
  transition: 100ms;
  :hover {
    background: ${props => props.editorial && "#F5F8F8"};
    box-shadow: ${props => props.editorial && "0 15px 25px 0 rgba(0,0,0,0.08)"};
    .teaserContainer {
      border-bottom: ${props => props.editorial && "1px solid transparent"};
    }
    + div {
      .teaserContainer {
        border-bottom: ${props => props.editorial && "1px solid transparent"};
      }
    }

    @media ${device.desktop} {
      .readTag {
        opacity: 1;
      }
    }
  }
`
export const TeaserContainer = styled.div`
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: ${props => (props.little ? "row" : "column")};
  align-items: ${props => (props.product ? "center" : "flex-start")};
  text-align: ${props => (props.product ? "center" : null)};
  overflow: hidden;
  display: ${props => props.secondColumn && "none"};
  padding-bottom: ${props => props.editorial && "25px"};
  padding-top: ${props => props.editorial && "20px"};
  border-bottom: ${props => props.editorial && "1px solid #D7E1E2"};

  margin: 0px auto;

  width: ${props =>
    props.editorial && `calc(100% - 2 *${props.theme.mobileGutter})`};
  max-width: ${props => props.theme.mainMaxWidth};

  @media ${device.tablet} {
    width: ${props =>
      props.editorial && `calc(100% - 2 *${props.theme.desktopGutter})`};
  }

  @media ${device.desktop} {
    display: ${props => props.secondColumn && "flex"};

    padding-bottom: ${props => props.editorial && "40px"};
    padding-top: ${props => props.editorial && "36px"};
  }

  > a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    ::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  :hover {
    .image {
      transform: ${props =>
        props.noHover
          ? "scale(1)"
          : props.product
          ? "scale(1.0)"
          : "scale(1.12)"};
      transition: 400ms;
    }

    .title {
      color: ${props =>
        props.editorial || props.product || props.noHover
          ? null
          : props.theme.colors.teal};
      transition: 100ms;
    }

    .button {
      background: #c8295f;
      transition: 70ms;
    }
  }
`

export const ButtonContainer = styled.div`
  margin-top: auto;

  .button {
    margin-top: 14px;
    @media ${device.tablet} {
      margin-top: 20px;
    }
  }
`

export const ImageContainer = styled.div`
	padding-top: ${props =>
    props.little ? 0 : props.product ? "100%" : "70.5357%"};
	position: relative;
	/* width: ${props => (props.product ? "calc(100% - 40px)" : "100%")};
	height: ${props => (props.product ? "calc(100% - 40px)" : "100%")}; */
	width: 100%;
	height: 100%;
	flex: 0;
	display: ${props => (props.editorial ? "none" : "flex")};
	justify-content: center;
	overflow:hidden;
	align-items: center;
	margin-bottom: ${props => (props.little || props.product ? "0px" : "13px")};
  flex-basis: ${props => (props.little ? "33%" : null)};
  flex-shrink: ${props => (props.little ? "0" : null)};
  margin-right: ${props => (props.little ? "16px" : null)};



	@media ${device.tablet} {
		margin-bottom: ${props =>
      props.little
        ? "0"
        : props.community
        ? "15px"
        : props.product
        ? "25px"
        : "19px"};
	}

  @media ${device.desktop} {
  flex-basis: ${props => (props.little ? "42%" : null)};
  margin-right: ${props => (props.little ? "26px" : null)};


  }

	@media ${device.large} {
		margin-bottom: ${props => (props.community ? "20px" : null)};
	}

	img, .gatsby-image-wrapper {
		width: 100%;
		height: 100%;
		margin: 0;
		object-fit: ${props => (props.product ? "contain" : "cover")};
		padding: ${props => (props.product ? "20px" : "0")};
		display: block;
		position: absolute !important;
		top: 0;
		left: 0;
		transition: 500ms;
		transform: ${props => (props.product ? "scale(0.9)" : "scale(1)")};

		@media ${device.tablet} {
			padding:0;
		}
	}
`

export const ImageWrapper = styled.div`
  height: ${props => (props.little ? "100%" : null)};
  width: ${props => (props.little ? "100%" : null)};
  padding-top: ${props => (props.little ? "71.287%" : null)};
`

export const Categories = styled.div`
  display: ${props => (props.editorial ? "flex" : "none")};
  position: ${props => (props.editorial ? null : "absolute")};
  align-items: center;
  top: 0;
  right: 0;
  z-index: 3;
  margin-top: -2px;
  background: ${props => (props.editorial ? "transparent" : "white")};
  height: 32px;

  @media (min-width: 400px) {
    display: ${props =>
      props.little || props.community || props.small || props.product
        ? "none"
        : "flex"};
  }

  @media ${device.large} {
    height: 42px;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    :hover {
      span {
        color: ${props => props.theme.colors.teal};
        opacity: ${props => props.editorial && 0.7};
      }
    }
  }
  span {
    text-transform: uppercase;
    color: ${props => (props.editorial ? "#24A49E" : "#85a2a0")};
    font-weight: 600;
    font-size: ${props => (props.editorial ? "10px" : "8px")};
    padding-left: ${props => (props.editorial ? null : "13px")};
    padding-right: ${props => props.editorial && "15px"};

    white-space: nowrap;

    @media ${device.large} {
      font-size: ${props => (props.editorial ? "12px" : "10px")};
      padding-left: ${props => (props.editorial ? null : "17px")};
      padding-right: ${props => props.editorial && "20px"};
    }
  }
`

export const Title = styled.h3`
  font-size: ${props =>
    props.editorial
      ? "26px"
      : props.community
      ? "24px"
      : props.product
      ? "14px"
      : props.carousel || props.little
      ? "16px"
      : props.small
      ? "14px"
      : "18px"};
  line-height: ${props =>
    props.little ? "150%" : props.small ? "140%" : "120%"};
  color: ${props => props.theme.colors.black};
  margin-bottom: 0;
  position: relative;
  font-family: ${props => (props.small || props.little ? "AvenirNext" : null)};
  font-weight: ${props => (props.small ? "600" : null)};
  @media ${device.tablet} {
    font-size: ${props =>
      props.editorial
        ? "27px"
        : props.community
        ? "28px"
        : props.product || props.little
        ? "16px"
        : props.small
        ? "15px"
        : "20px"};
  }

  @media ${device.desktop} {
    font-size: ${props =>
      props.editorial || props.community
        ? "32px"
        : props.product
        ? "20px"
        : null};
    max-width: ${props => props.editorial && "900px"};
  }
  @media ${device.large} {
    font-size: ${props =>
      props.editorial
        ? "32px"
        : props.community
        ? "36px"
        : props.product
        ? "22px"
        : props.small || props.little
        ? "16px"
        : "22px"};
  }
`

export const Date = styled.span`
  font-size: 12px;
  font-weight: 600;
  font-family: "AvenirNext";
  color: #9cacae;
  margin-top: 8px;

  @media ${device.desktop} {
    margin-top: 12px;
  }
`

export const Excerpt = styled.p`
  display: ${props =>
    (props.little ||
      props.community ||
      props.small ||
      props.product ||
      props.editorial) &&
    "none"};
  margin-top: 9px;
  font-size: 14px;
  line-height: 143%;
  color: ${props => props.theme.colors.grey[3]};
  position: relative;

  @media ${device.tablet} {
    margin-top: 19px;
  }

  @media ${device.large} {
    font-size: 16px;
  }

  ::after {
    content: "";
    width: 100%;
    position: absolute;
    top: 30px;
    left: 0;
    height: 74px;
    display: ${props =>
      props.community || props.small || props.product ? "none" : "block"};
    background: -webkit-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: -moz-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: -o-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: -ms-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );

    @media ${device.desktop} {
      top: 30px;
      height: 80px;
    }

    @media ${device.large} {
      top: 35px;
      height: 100px;
    }
  }
`

export const Artist = styled.p`
  font-size: 14px;
  z-index: 1;
  margin-top: 5px;
  pointer-events: none;
  a,
  span {
    color: ${props => props.theme.colors.pink};
    font-weight: 600;
    text-decoration: none;
  }

  @media ${device.tablet} {
    margin-top: 10px;
  }

  @media ${device.desktop} {
    font-size: 16px;
    margin-top: 5px;
  }

  @media ${device.large} {
    font-size: 18px;
    margin-top: 10px;
  }
`

export const ReadTag = styled.span`
  font-size: 18px;
  color: #46b2ad;
  font-weight: 600;
  font-family: "AvenirNext";
  position: absolute;
  right: 24px;
  top: 50%;
  opacity: 0;
  transition: 100ms;
`
