import React, { useState, useEffect, useRef } from "react"
import useMedia from "../../hooks/useMedia"
import Square from "../../images/adPlaceHolders/ig-banner-square-2.jpg"
import Vertical from "../../images/adPlaceHolders/ig-banner-vertical.jpg"
import HorizontalTablet from "../../images/adPlaceHolders/ig-banner-horizontal-tablet.jpg"
import HorizontalLarge from "../../images/adPlaceHolders/ig-banner-horizonal-large.jpg"
import { AdImage } from "./style"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const GoogleAd = ({ className, style, client, slots }) => {
  const myRef = useRef()
  const [placeholderVisible, setPlaceholderVisible] = useState(false)

  let placeholders

  if (className === "articleHorizontal") {
    placeholders = [Square, Square, HorizontalTablet]
  }

  if (className === "articleVertical") {
    placeholders = [Square, Vertical, Vertical]
  }
  if (className === "horizontal") {
    placeholders = [Square, HorizontalTablet, HorizontalLarge]
  }

  if (className === "square") {
    placeholders = [Square]
  }

  if (className === "vertical") {
    placeholders = [Vertical]
  }

  const chosenSlot = useMedia(
    // Media queries
    [
      "(max-width: 767px)",
      "(min-width: 768px) and (max-width: 1279px)",
      "(min-width: 1280px)",
    ],
    //options
    slots,
    // default
    slots[0]
  )

  const chosenPlaceholder = useMedia(
    // Media queries
    [
      "(max-width: 767px)",
      "(min-width: 768px) and (max-width: 1279px)",
      "(min-width: 1280px)",
    ],
    //options
    placeholders,
    // default
    placeholders[0]
  )

  useEffect(() => {
    if (window) (window.adsbygoogle = window.adsbygoogle || []).push({})

    const placholderTimer = setTimeout(() => {
      if (!myRef.current.children.length) {
        setPlaceholderVisible(true)
      }
    }, 0)

    return () => clearTimeout(placholderTimer)
  }, [])

  return (
    <>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      ></script>
      {placeholderVisible && (
        <AdImage
          className={`${className}`}
          // This is the main image, just comment this out if need to add a different ad
          href="https://www.instagram.com/inspirationgrid"
          // This is the secondary image link, uncomment this if want to change the ads to something else
          // href="https://www.theguardian.com/australia-news/2019/dec/21/how-you-can-donate-and-help-the-volunteer-firefighters-in-australias-bushfire-crisis"
          rel="noopener noreferrer"
          target="_blank"
          onClick={() => {
            trackCustomEvent({
              category: "banner",
              action: "clicked",
              label: "instaBanner",
            })
          }}
          loading="lazy"
        >
          <img src={chosenPlaceholder} alt="Inspiration Grid Instagram" />
        </AdImage>
      )}

      {!placeholderVisible && (
        <ins
          className={`${className} adsbygoogle`}
          style={style || { display: "block" }}
          data-ad-client={client}
          data-ad-slot={chosenSlot}
          ref={myRef}
        />
      )}
    </>
  )
}

export default GoogleAd
