import styled from "styled-components"

import { device } from "../../utils/breakpoints"

export const SocialLinksContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${props => (props.footer ? "180px" : "none")};
  justify-content: ${props => (props.footer ? "space-between;" : "none")};

  @media ${device.tablet} {
    margin-top: ${props => (props.article ? "25px" : "0")};
    flex-direction: ${props => (props.article ? "column;" : "row")};
    width: ${props =>
      props.article ? "60px" : props.footer ? "180px" : "100%"};
    justify-content: ${props =>
      props.footer ? "space-between;" : "flex-start"};
  }
  @media ${device.tablet} {
    height: ${props => (props.article ? "120px" : "100%")};
    margin-right: ${props =>
      props.article ? null : props.footer ? "-24px" : "-12px"};
  }

  a {
    padding: 0;
    margin-right: 12px;

    :hover {
      opacity: 0.6;
      transition: 70ms;
    }
    @media ${device.tablet} {
      margin-right: ${props => (props.article ? "0" : "24px")};
      margin-bottom: ${props => (props.article ? "12px" : "0")};
    }
    
    @media ${device.desktop} {
      margin-bottom: ${props => (props.article ? "22px" : "0")};
    }

    &.twitter-logo {
      width: ${props => (props.footer || props.article ? "20px" : "16px")};
      g,
      path {
        fill: ${props =>
          props.article ? props.theme.colors.BrightTurquoise : ""};
      }

      svg {
        path {
          fill: ${props => props.theme.colors.BrightTurquoise};
        }
      }
    }
    &.facebook {
      g,
      path {
        fill: ${props => (props.article ? props.theme.colors.SanMarino : "")};
      }
    }
    &.instagram {
      display: ${props => (props.article ? "none" : "16px")};
    }
    &.pinterest {
      g,
      path {
        fill: ${props => (props.article ? props.theme.colors.Cardinal : "")};
      }
    }
  }

  svg {
    display: block;
    height: ${props => (props.footer || props.article ? "20px" : "16px")};
    width: ${props => (props.footer || props.article ? "20px" : "16px")};
    g,
    path {
      fill: ${props => (props.footer ? props.theme.colors.turquoise : "")};
    }
  }
`
