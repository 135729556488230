import styled from "styled-components"
import { device } from "../../utils/breakpoints"

export const SliderGridContainer = styled.div`
  margin: 0px auto;
  width: 100%;
  margin-top: ${props => (props.article ? "20px" : null)};
  overflow: visible;
  -webkit-overflow-scrolling: touch;
  max-width: ${props => props.theme.mainMaxWidth};
  margin-top: ${props => (props.editorial || props.article ? "40px" : null)};

  margin-bottom: 40px;
  @media ${device.tablet} {
    width: ${props =>
      props.article ? "100%" : `calc(100% - 2 *${props.theme.desktopGutter})`};
  }

  @media ${device.desktop} {
  }

  @media ${device.large} {
  }

  > h3 {
    font-size: 24px;
    color: ${props => props.theme.colors.grey[1]};
    margin-bottom: 17px;

    @media ${device.tablet} {
      font-size: 28px;
      margin-bottom: 25px;
    }
  }
`

export const SliderGridWrapper = styled.div`
	display: ${props => (props.editorialPost ? "grid" : "flex")};
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	width: 100%;
	padding-left: ${props => (props.article ? 0 : "20px")};
	padding-top: ${props => props.editorial && "20px"};
	/* border-top: ${props => props.editorial && "1px solid #D7E1E2"}; */
	grid-template-columns: ${props => (props.editorialPost ? "1fr" : null)};
	grid-row-gap: ${props => (props.editorialPost ? "30px" : null)};


	::-webkit-scrollbar {
		width: 10px !important;
		height: 10px !important;
		display: none !important;
	}

	@media ${device.tablet} {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: ${props => props.theme.mobileGap};
		grid-row-gap: 30px;
		padding-left:0;
		overflow: visible;
	}

	@media ${device.desktop} {
		grid-template-rows: ${props => (props.editorial ? "1fr" : "repeat(2, 1fr)")};
		grid-template-columns: ${props =>
      props.editorial
        ? "1fr 1fr 1fr 1fr"
        : props.article
        ? "1fr"
        : "1fr 1fr 1fr"};
		grid-column-gap: ${props =>
      props.article ? props.theme.mobileGapGap : props.theme.desktopGap};
		grid-row-gap: ${props => (props.article ? "20px" : "40px")};
		padding-top: ${props => (props.editorial ? "40px" : 0)};


	}

	@media ${device.large} {
		grid-column-gap: ${props =>
      props.article ? props.theme.mobileGapGap : props.theme.largeGap};
		grid-row-gap: ${props => props.editorialPost && "40px"};
		padding-top: ${props => props.editorial && "60px"};

	}

	.teaser {
		flex: ${props =>
      props.editorialPost ? null : props.editorial ? "1 0 80%" : "1 0 65%"};
		padding-right: ${props => (props.editorialPost ? null : "15px")};

		.excerpt {
			display: none;
		}

		@media ${device.tablet} {
			padding: 0;
			.excerpt {
				display: block;
			}
		}
	}

	.categories {
		/* padding-right: 20px; */
		@media ${device.tablet} {
			padding-right: 0;
		}
	}
`

export const GridContainer = styled.div`
  border-top: ${props =>
    props.related && `1px solid ${props.theme.colors.grey[1]}`};
  padding-top: ${props => props.related && "30px"};

  margin: 0px auto;
  display: ${props => (props.hide ? "none" : props.articleBottom && "none")};
  width: ${props =>
    props.article || props.related || props.articleBottom
      ? "100%"
      : `calc(100% - 2 *${props.theme.mobileGutter})`};
  max-width: ${props => props.theme.mainMaxWidth};
  margin-top: ${props =>
    props.marginTop ? "50px" : props.related ? "40px" : null};

  @media ${device.tablet} {
    width: ${props =>
      props.article || props.related || props.articleBottom
        ? "100%"
        : `calc(100% - 2 *${props.theme.desktopGutter})`};
  }

  @media ${device.desktop} {
    margin-top: ${props => props.related && "0px"};
    margin-top: ${props => (props.marginTop ? "45px" : null)};
    grid-column: ${props => (props.related || props.articleBottom) && "span 3"};
    display: ${props => props.articleBottom && "block"};
    padding-top: ${props => props.related && "60px"};
  }

  @media ${device.large} {
    margin-top: ${props => (props.marginTop ? "108px" : null)};
  }
`

export const ResultsGridContainer = styled.div`
  margin-top: ${props => (props.search ? "20px" : "27px")};

  @media (min-width: 375px) {
    margin-top: ${props => (props.search ? "20px" : "40px")};
  }

  @media ${device.tablet} {
    margin-top: ${props => (props.search ? "30px" : "46px")};
  }

  @media ${device.desktop} {
    margin-top: ${props => (props.search ? "40px" : "55px")};
  }
`

export const HomeGridContainer = styled.div`
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  @media ${device.tablet} {
    margin-bottom: 60px;
  }

  @media ${device.desktop} {
    /* margin-top: 200px; */
  }

  @media ${device.large} {
    /* margin-top: 240px; */
    margin-bottom: 120px;
  }

  .button {
    width: ${props => `calc(100% - 2 *${props.theme.mobileGutter})`};
    max-width: 1200px;

    @media ${device.tablet} {
      width: ${props => `calc(100% - 2 *${props.theme.desktopGutter})`};
    }
  }
`

export const StandardGridWrapper = styled.div`
  display: grid;
  grid-column-gap: ${props => props.theme.mobileGap};
  grid-row-gap: 20px;
  grid-template-columns: 1fr;
  margin-bottom: 40px;
  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 30px;
  }

  @media ${device.desktop} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: ${props => props.theme.desktopGap};
    grid-row-gap: 40px;
  }

  @media ${device.large} {
    grid-column-gap: ${props => props.theme.largeGap};
  }
`

export const SmallGridWrapper = styled(StandardGridWrapper)`
  grid-template-columns: 1fr 1fr;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 30px;
  }

  @media ${device.desktop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 40px;
  }
`

export const EditorialSmallGridWrapper = styled(StandardGridWrapper)`
  border-top: 1px solid #d7e1e2;
  padding-top: 40px;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;

  @media ${device.tablet} {
    padding-top: 20px;

    grid-template-columns: 1fr 1fr;
  }

  @media ${device.desktop} {
    padding-top: 40px;

    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${device.large} {
    padding-top: 60px;
    margin-bottom: 60px;
  }
`

export const ProductsGridWrapper = styled(SmallGridWrapper)`
  border-bottom: ${props =>
    props.article || props.articleBottom ? "none" : "1px solid #D7E1E2"};
  padding-bottom: ${props => (props.article ? "0px" : "40px")};
  /* padding-bottom:40px; */

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 40px;
    padding-bottom: ${props => (props.article ? "0px" : "80px")};
    margin-bottom: ${props =>
      props.article || props.articleBottom ? "0px" : "60px"};
  }

  @media ${device.desktop} {
    grid-row-gap: ${props =>
      props.article || props.articleBottom ? "40px" : "60px"};
    grid-template-columns: ${props =>
      props.articleBottom
        ? "1fr 1fr 1fr 1fr 1fr 1fr"
        : props.article
        ? "1fr 1fr"
        : "1fr 1fr 1fr 1fr"};
    margin-bottom: ${props =>
      props.article || props.articleBottom ? "0px" : "90px"};
  }

  @media ${device.large} {
    grid-row-gap: ${props =>
      props.articleBottom ? "60px" : props.article ? "40px" : "76px"};
  }

  .title {
    font-size: ${props => (props.article || props.articleBottom) && "16px"};
  }

  .button {
    height: ${props => (props.article || props.articleBottom) && "36px"};

    a {
      font-size: ${props => (props.article || props.articleBottom) && "14px"};
    }
  }
`

export const Title = styled.h2`
  font-size: 42px;
  color: ${props => props.theme.colors.black};
  line-height: 100%;
  margin-bottom: 0px;
  display: ${props => (props.inline ? "inline" : "block")};

  @media (min-width: 430px) {
    display: inline-block;
  }

  @media ${device.tablet} {
    font-size: 48px;
    margin-bottom: ${props => (props.inline ? "0" : "25px")};
  }

  @media ${device.large} {
    font-size: 64px;
    margin-bottom: ${props => (props.inline ? "0" : "50px")};
  }
`

export const Secondary = styled(Title)`
  color: ${props => props.theme.colors.grey[4]};
  margin-left: ${props => (props.inline ? "10px" : "0")};
  margin-bottom: ${props => (props.inline ? "0" : "20px")};
  display: ${props => (props.inline ? "inline" : "block")};

  @media (min-width: 430px) {
    margin-left: 10px;
  }
  @media ${device.tablet} {
    margin-left: 14px;
  }
`

export const ErrorGreetings = styled.div`
  width: calc(100% - 40px);
  max-width: 1200px;
  margin: 0 auto;
  /* z-index: 100; */
  position: relative;
  margin-bottom: 40px;

  h3 {
    font-size: 30px;
  }

  p {
    font-size: 16px;
  }

  @media ${device.tablet} {
    width: calc(100% - 80px);
    margin-bottom: 50px;

    p {
      max-width: 56%;
    }
  }
  @media ${device.large} {
    padding: 0;
    margin-bottom: 70px;

    h3 {
      font-size: 48px;
    }

    p {
      font-size: 20px;
    }
  }
`
