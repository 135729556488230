import React from 'react';

import { SocialLinksContainer } from './style.js';

import { ReactComponent as FacebookLogo } from '../../images/facebook-logo.svg';
import { ReactComponent as InstagramLogo } from '../../images/instagram-logo.svg';
import { ReactComponent as TwitterLogo } from '../../images/twitter-logo.svg';
import { ReactComponent as PinterestLogo } from '../../images/pinterest-logo.svg';

const SocialLinks = (props) => {
	return (
		<SocialLinksContainer footer={props.footer} article={props.article}>
			<a  target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/inspirationgrid/" className="instagram">
				<InstagramLogo />
			</a>
			<a  target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/TheInspirationGrid/" className="facebook">
				<FacebookLogo />
			</a>
			<a  target="_blank" rel="noopener noreferrer" href="https://twitter.com/inspirationgrid" className="twitter-logo" >
				<TwitterLogo />
			</a>
			<a  target="_blank" rel="noopener noreferrer" href="https://www.pinterest.com.au/inspirationgrid/" className="pinterest">
				<PinterestLogo />
			</a>

		</SocialLinksContainer>
	);
};

export default SocialLinks;
