import React from 'react';
import { TitleContainer, Title, Secondary, ButtonContainer, PreviousButton, NextButton } from './style.js';
const CarouselTitle = ({ prevDisabled, nextDisabled, next, previous, header, title }) => {
	return (
		<TitleContainer header={header} className="carousel-title">
			<Title>{title}</Title>
			{header ? null : <Secondary>inspiration</Secondary>}

			<ButtonContainer header={header}>
				<PreviousButton onClick={previous} prevDisabled={prevDisabled}>
					<span>
						<svg viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M.921 8c0-.276.106-.552.316-.763L8.474 0 10 1.526 3.526 8 10 14.474 8.474 16 1.237 8.763A1.076 1.076 0 0 1 .921 8z"
								fill="#545C52"
							/>
						</svg>
					</span>
				</PreviousButton>

				<NextButton onClick={next} nextDisabled={nextDisabled}>
					<span>
						<svg viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M10 8c0-.276-.106-.552-.316-.763L2.447 0 .92 1.526 7.395 8 .921 14.474 2.447 16l7.237-7.237c.21-.21.316-.487.316-.763z"
								fill="#545C52"
							/>
						</svg>
					</span>
				</NextButton>
			</ButtonContainer>
		</TitleContainer>
	);
};

export default CarouselTitle;
