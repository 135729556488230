import styled from "styled-components"
import { device } from "../../utils/breakpoints"

export const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-self: ${props => (props.article ? null : "flex-end")};
  @media ${device.desktop} {
    flex-direction: row;
  }

  @media ${device.large} {
    width: ${props => props.article && "574px"};
  }

  ::after {
    display: ${props => props.white && "none"};
    content: "";
    position: absolute;
    bottom: 60px;
    width: 100%;
    height: 3px;
    left: 0;
    background: #000;
    @media ${device.tablet} {
      bottom: 0;
    }
  }
`

export const SupportiveText = styled.h4`
  font-size: 20px;
  font-weight: 600;
  color: ${props => props.theme.colors.black};
  margin-bottom: 10px;
  flex-shrink: 0;

  @media ${device.tablet} {
    font-size: 28px;
    margin-bottom: 14px;
  }

  @media ${device.desktop} {
    margin-bottom: 0;
    padding-bottom: 6px;
    height: 48px;
    display: flex;
    align-items: center;
  }
`

export const Form = styled.form`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    /* -webkit-box-shadow: 0 0 0 30px #212c1f inset !important; */
    -webkit-text-fill-color: grey !important;
  }
  flex-grow: 1;
  margin: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  @media ${device.tablet} {
    flex-direction: row;
    padding-bottom: 15px;
  }
  input {
    width: 100%;
    border: none;
    font-size: ${props => (props.white ? "14px" : "16px")};
    outline: none;
    background: none;
    background-color: transparent;
    cursor: text;
    color: ${props =>
      props.white && props.showError
        ? "#FFEDB5"
        : props.showError
        ? "#C42B31"
        : props.white
        ? "#fff"
        : null};
    position: relative;
    padding-bottom: 13px;
    margin-bottom: 13px;
    font-weight: ${props => (props.white ? "600" : null)};

    @media ${device.tablet} {
      font-size: ${props => (props.white ? "16px" : "18px")};
      padding-bottom: 0px;
      margin-bottom: 0px;
    }

    @media ${device.desktop} {
      font-size: 18px;
      margin-left: ${props => (props.white ? "0" : "20px")};
    }

    @media ${device.large} {
      margin-left: ${props => (props.white ? "0" : "28px")};
    }

    ::placeholder {
      color: ${props => (props.white ? "white" : props.theme.colors.grey[3])};

      opacity: 1;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${props =>
      props.white ? "#fff" : "black"} !important;
  }

  ::after {
    display: ${props => (props.white ? "block" : "none")};
    content: "";
    position: absolute;
    bottom: 60px;
    width: 100%;
    height: 3px;
    left: 0;
    background: #fff;

    opacity: ${props => (props.disabled ? "0.4" : "1")};
    @media ${device.tablet} {
      bottom: 0;
    }
  }
`

export const SubmitButton = styled.button`
  width: 100%;
  justify-content: flex-end;
  background: ${props => props.theme.colors.pink};
  color: white;
  font-size: 14px;
  font-weight: 600;
  height: 48px;
  text-align: right;
  padding: 0 20px;
  outline: none;
  border: none;
  border-radius: 3px;
  cursor: pointer;

  opacity: ${props => (props.disabled ? "0" : "none")};
  pointer-events: ${props => (props.disabled ? "none" : "all")};

  :hover {
    background: #c8295f;
    transition: 70ms;
  }

  @media ${device.tablet} {
    /* position: absolute;
		top: -13px;
		right: 0; */
    width: auto;
    text-align: center;
    flex-shrink: 0;
    font-size: 18px;
  }
`

export const Sent = styled.div`
  position: absolute;
  left: 0;
  top: ${props => (props.white ? "-15px" : "-3px")};
  display: ${props => (props.disabled ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-bottom: 0;
    margin-right: 14px;

    @media ${device.tablet} {
      width: 25px;
      height: 25px;
    }
  }

  p {
    color: ${props => (props.white ? "#fff" : "#4A5050")};
    font-weight: ${props => (props.white ? "600" : "400")};
    line-height: 110%;
    margin-bottom: 0;
    font-size: 14px;

    @media ${device.tablet} {
      font-size: 16px;
    }

    @media ${device.large} {
      font-size: 18px;
    }
  }

  @media (min-width: 450px) {
    top: ${props => (props.white ? "-5px" : "3px")};

    br {
      display: none;
    }
  }

  @media ${device.tablet} {
    top: 20px;
    left: ${props => (props.white ? "0" : "30px")};
  }

  @media ${device.desktop} {
    top: 10px;
  }
`

export const ErrorMessage = styled.div`
  position: absolute;
  right: 0;
  bottom: -35px;
  display: flex;
  justify-content: center;
  border-radius: 2px;
  align-items: center;
  background: #930000;
  opacity: ${props => (props.showError ? "1" : "0")};
  padding: 5px 8px;
  pointer-events: none;
  svg {
    width: 17px;
    margin-bottom: 0;
    margin-right: 9px;

    path {
      fill: #ffbf50;
    }

    @media ${device.tablet} {
      width: 25px;
      height: 25px;
      margin-right: 12px;
    }
  }

  @media ${device.tablet} {
    bottom: -43px;
    padding: 5px 12px;
  }

  @media ${device.large} {
    bottom: -60px;
    padding: 10px 15px;
  }

  p {
    color: #fff;
    font-weight: 600;
    line-height: 110%;
    margin-bottom: 0;
    font-size: 11px;

    @media ${device.tablet} {
      font-size: 13px;
    }

    @media ${device.large} {
      font-size: 14px;
    }
  }
`
