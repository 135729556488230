import React from 'react';

import { GridContainer, ProductsGridWrapper } from './style.js';

const ProductsGrid = ({ marginTop, articleBottom, article, children }) => {
	return (
		<GridContainer marginTop={marginTop} articleBottom={articleBottom} article={article}>
			<ProductsGridWrapper articleBottom={articleBottom} article={article}>{children}</ProductsGridWrapper>
		</GridContainer>
	);
};

export default ProductsGrid;
