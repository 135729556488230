import styled from 'styled-components';

import { device } from '../../utils/breakpoints';

export const MegaMenuContainer = styled.div`
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;

	@media ${device.desktop} {
		pointer-events: all;

		::before {
			content: '';
			position: absolute;
			left: 60px;
			top: 42px;
			height: 100px;
			width: 92%;
			margin: 0 auto;
			/* border: 1px solid red; */
			pointer-events: ${(props) => (props.isMenuShown ? 'all' : 'none')};
		}
	}
`;

export const More = styled.div`
	display: none;
	color: #fff;
	padding: 0 17px;
	z-index: ${(props) => props.isSearchOpen && -3};
	cursor: pointer;

	@media ${device.large} {
		padding: 0 24px;
	}

	span {
		font-size: 16px;
		position: relative;
		font-weight: 600;
	}
	span::after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		opacity: 0;
		transform: ${(props) => (props.isMenuShown ? ' translateY(4px)' : ' translateY(6px)')};
		opacity: ${(props) => (props.isMenuShown ? ' 1' : '0')};
		transition: 230ms;
		height: 2px;
		width: 100%;
		background: #fff;
	}

	@media ${device.desktop} {
		display: block;
	}
`;
export const MegaMenuMainWrapper = styled.div`
	position: absolute;
	left: 50%;
	bottom: -18px;
	max-width: 1200px;
	margin: 0 auto;
	width: 100%;
	border-radius: 2px;
	overflow: hidden;
	transform: translateX(-50%) translateY(100%);
	box-shadow: 10px 15px 35px 0 rgba(0, 0, 0, 0.35);
	transition: 100ms;
	opacity: ${(props) => (props.isHamburgerClicked ? '1' : '0')};
	pointer-events: ${(props) => (props.isHamburgerClicked ? 'all' : 'none')};
	z-index: 1;

	@media ${device.desktop} {
		bottom: -12px;
		opacity: ${(props) => (props.isMenuShown ? '1' : '0')};
		pointer-events: ${(props) => (props.isMenuShown ? 'all' : 'none')};
	}
`;
export const MegaMenuMain = styled.nav`
	display: grid;
	grid-template-columns: 1fr;
	background: #fff;
	transition: 1100ms;

	@media ${device.desktop} {
		grid-template-columns: 1fr 21.6666% 1fr;
	}
`;

export const Clickable = styled.div`
	display: block;
	height: 100%;
	width: 100%;
	z-index: ${(props) => (props.isSearchOpen ? -3 : 4)};
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: all;

	@media ${device.desktop} {
		display: none;
	}

	::before {
		content: '';
		position: absolute;
		height: 50px;
		width: 50px;
		transform: translateX(-50%) translateY(-50%);
		top: 50%;
		cursor: pointer;
		left: 50%;
	}
`;

export const HamburgerWrapper = styled.div`
	display: inline-block;
	width: 20px;
	height: 16px;
	position: relative;

	cursor: pointer;

	@media ${device.tablet} {
		height: 18px;
		width: 23px;
	}
`;

export const FirstLine = styled.span`
	display: block;
	height: 2px;
	width: ${(props) => (props.isHamburgerClicked ? ' 13px' : '100%')};
	transition: 0.3s ease-out;
	background: #fff;
	position: absolute;
	transform: ${(props) =>
		props.isHamburgerClicked ? 'translate3d(5px, 6px, 0) rotate(45deg)' : 'translate3d(0, 0, 0) rotate(0deg)'};

	@media ${device.tablet} {
		width: ${(props) => (props.isHamburgerClicked ? ' 17px' : '100%')};
	}
`;

export const SecondLine = styled(FirstLine)`
  transition: 0.1s ease-out;
  opacity: ${(props) => (props.isHamburgerClicked ? 0 : 1)};
  top: 7px;

  @media ${device.tablet} {
    top: 8px;
  }
`;

export const ThirdLine = styled(FirstLine)`
  transform: ${(props) =>
		props.isHamburgerClicked ? 'translate3d(-4px, 6px, 0) rotate(-45deg)' : 'translate3d(0, 14px, 0) rotate(0deg)'};
  display: block;

  @media ${device.tablet} {
    transform: ${(props) =>
		props.isHamburgerClicked ? 'translate3d(-6px, 6px, 0) rotate(-45deg)' : 'translate3d(0, 16px, 0) rotate(0deg)'};
  }
`;

export const MenuSectionContainer = styled.div`
	padding: 22px 40px 20px 40px;
	border-bottom: ${(props) => `1px solid ${props.theme.colors.grey[1]}`};

	@media ${device.desktop} {
		padding: 34px 50px 50px 50px;
		border-bottom: 1px solid transparent;
		border-right: ${(props) => `1px solid ${props.theme.colors.grey[1]}`};
	}
`;

export const MenuTitle = styled.h2`
	color: ${(props) => props.theme.colors.grey[4]};
	line-height: 42px;
	font-size: 24px;
	margin-bottom: 3px;

	@media ${device.desktop} {
		font-size: 36px;
		margin-bottom: 13px;
	}
`;

export const MenuListing = styled.ul`
	display: grid;
	grid-template-columns: ${(props) => (props.var ? '1fr' : 'repeat(auto-fit, minmax(120px, auto))')};
	grid-column-gap: 30px;
	grid-row-gap: ${(props) => (props.footer ? '7px' : 'none')};
	list-style: none;
	margin: 0;

	@media (min-width: 550px) {
		display: ${(props) => props.var && 'flex'};
		justify-content: ${(props) => props.var && 'space-between'};
		max-width: ${(props) => props.var && '400px'};
		grid-template-rows: repeat(10, auto);
		grid-auto-flow: column;
	}

	@media (min-width: 550px) {
		grid-template-rows: repeat(7, auto);
	}

	@media (min-width: 700px) {
		grid-template-rows: repeat(6, auto);
	}

	@media ${device.desktop} {
		grid-template-rows: repeat(11, auto);

		display: grid;
		grid-column-gap: 20px;
		grid-template-columns: ${(props) => (props.var ? '1fr' : '1fr 1fr')};
	}
`;

export const MenuListItem = styled.li`
	display: inline-block;
	margin-bottom: 0;

	:hover {
		transition: 70ms;
		a {
			color: ${(props) => props.theme.colors.teal};
		}
	}

	a {
		text-decoration: none;
		font-size: ${(props) => (props.footer ? '12px' : '14px')};
		font-weight: 600;
		color: ${(props) => props.theme.colors.black};
		display: block;
		height: 36px;

		@media ${device.tablet} {
			font-size: 14px;
		}
	}
`;

export const MenuSocialContainer = styled.div`
	padding: 30px 40px 25px 40px;
	position: relative;
	left: -13px;

	svg {
		height: 19px;

		&.twitter-logo {
			width: 20px;
		}
		g {
			fill: ${(props) => props.theme.colors.teal};
		}
		path {
			fill: ${(props) => props.theme.colors.teal};
		}
	}

	.twitter-logo {
		svg {
		  path {
			fill: ${(props) => props.theme.colors.teal} !important;
		  }
		}
	  }

	@media ${device.desktop} {
		display: none;
	}
`;

export const PopularSection = styled.div`
	padding: 22px 30px 20px 38px;
	display: none;
	width: 100%;
	height: 100%;
	@media ${device.desktop} {
		display: block;
	}

	@media ${device.desktop} {
		padding: 34px 0px 20px 0px;
	}
`;

export const Overlay = styled.div`
	width: 100vw;
	margin-left: calc(-50vw + 50%);
	position: fixed;
	height: 100vh;
	top: 0;
	left: 0;
	background: black;
	opacity: ${(props) => (props.isHamburgerClicked ? '0.3' : '0')};
	pointer-events: none;

	@media ${device.desktop} {
		opacity: ${(props) => (props.isMenuShown ? '0.3' : '0')};
		height: calc(100vh + 1000px);
	}
`;

export const CarouselWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;

	> div {
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		padding-left: 50px;
		padding-right: 40px;
	}

	.carousel-title {
		margin-bottom: 20px;
		width: 100%;
		h2 {
			align-self: flex-start;
			font-size: 36px;
			line-height: 42px;
			color: ${(props) => props.theme.colors.grey[4]};
		}
	}

	.width-container {
		margin: 0;
		width: 100%;
	}

	.card-container {
		/* flex: 1 0 65% !important; */
	}
`;
