import React from 'react';

import { GridContainer, SmallGridWrapper, Title, Secondary } from './style.js';

const RelatedGrid = ({title,children}) => {
	return (
		<GridContainer related>
			<Title>{title}</Title>
		<Secondary>inspiration</Secondary>
			<SmallGridWrapper>
				{children}
			</SmallGridWrapper>
		</GridContainer>
	);
};

export default RelatedGrid;