import React from 'react';

import { GridContainer, SmallGridWrapper } from './style.js';

const SmallGrid = ({children}) => {
	return (
		<GridContainer>
			<SmallGridWrapper>
				{children}
			</SmallGridWrapper>
		</GridContainer>
	);
};

export default SmallGrid;