export const theme = {
  primaryFont: "'AvenirNext','Helvetica Neue','Arial',sans-serif",
  mobileGutter: "20px",
  desktopGutter: "40px",
  mainMaxWidth: "1200px",
  mobileGap: "15px",
  desktopGap: "20px",
  largeGap: "50px",
  articleHeaderRatio: "calc(200px + 23.3vw)",
  colors: {
    grey: ["#F4F4F4", "#D7E1E2", "#9CACAE", "#4A5050", "#E2E6E9"],
    black: "#19191A",
    teal: "#24A49E",
    turquoise: "#00CDC2",
    pink: "#E4316E",
    zircon: "#D7E1E2",
    SanMarino: "#556CAA",
    BrightTurquoise: "#09CADB",
    Cardinal: "#CC1933",
  },
}
