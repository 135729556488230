import styled from "styled-components"
import { device } from "../../utils/breakpoints"
export const PageWidthContainer = styled.div`
  width: 100%;
  overflow: hidden;
  margin: 40px auto;

  @media ${device.tablet} {
    margin: 50px auto;
  }

  @media ${device.desktop} {
    margin: 80px auto;
  }
`

export const CarouselWidthContainer = styled.div`
  visibility: visible;

  @media ${device.large} {
    width: ${props => `calc(100% - 2*${props.theme.desktopGutter})`};
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
  }
`

export const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
  position: relative;
  overflow: visible;
`

export const CarouselContainer = styled.div`
  display: flex;
  transition: transform 1.5s cubic-bezier(0.23, 1, 0.32, 1);
  position: relative;
  overflow: auto;
  padding-left: 20px;
  margin: ${props => ` 0 calc(${props.theme.mobileGap} / -2)`};
  margin-bottom: 25px;
  -webkit-overflow-scrolling: touch;

  @media ${device.tablet} {
    margin-bottom: 40px;
  }
  @media ${device.desktop} {
    margin: ${props => ` 0 calc(${props.theme.desktopGap} / -2)`};
    margin-bottom: 40px;
    padding-left: ${props => (props.header ? "0" : "40px")};
		overflow:  ${props => (props.header ? "visible !important" : null)};
  }

  @media ${device.large} {
    margin: ${props => ` 0 calc(${props.theme.largeGap} / -2)`};
    margin-bottom: 60px;
    padding: 0;
  }
  ::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    display: none !important;
  }
`

export const CTAWrapper = styled.div`
  width: ${props => `calc(100% - 2*${props.theme.mobileGutter})`};
  margin: 0 auto;
  @media ${device.desktop} {
    width: ${props => `calc(100% - 2*${props.theme.desktopGutter})`};
  }

  @media ${device.large} {
    width: 100%;
  }
`

//Card.js
export const CardContainer = styled.div`
  display: flex;
  border: black;
  position: relative;
  /* height: 400px; */
  overflow: visible;
  flex: 1 0 65% !important;
  padding: ${props => ` 0 calc(${props.theme.mobileGap} / 2)`};

  @media ${device.tablet} {
    flex: ${props => (props.header ? "1 0 71.4285714286%" : "1 0 30% ")} !important;
  }
  @media ${device.desktop} {
    padding: ${props => ` 0 calc(${props.theme.desktopGap} / 2)`};
  }

  @media ${device.large} {
    flex: ${props => (props.header ? "1 0 71.4285714286%" : "1 0 25%")} !important;
    padding: ${props => ` 0 calc(${props.theme.largeGap} / 2)`};
  }

  .teaser {
    width: 100%;
  }
`

export const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  z-index: 2;
  opacity: 0.1;
`

//buttons

export const ButtonContainer = styled.div`
  display: ${props => (props.header ? "flex" : "none")};
  margin-left: auto;
  @media ${device.large} {
    display: flex;
  }
`

export const NextButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border: none;
  background: #fff;
  /* z-index: 4; */
  transition: 100ms;
  pointer-events: ${props => (props.nextDisabled ? "none" : null)};
  padding: 0;
  cursor: pointer;
  span {
    opacity: ${props => (props.nextDisabled ? "0.3" : "1")};
    height: auto;

    svg {
      height: 24px;
    }
  }

  :focus {
    outline: none;
  }

  :hover {
    path {
      transition: 80ms;
      fill: ${props => props.theme.colors.teal};
    }
  }
`

export const PreviousButton = styled(NextButton)`
  span {
    opacity: ${props => (props.prevDisabled ? "0.3" : "1")};
  }

  pointer-events: ${props => (props.prevDisabled ? "none" : null)};
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  margin-bottom: 16px;

  @media ${device.tablet} {
    align-items: center;
    margin-bottom: 26px;
    padding-left: 20px;
    flex-direction: row;
  }
  @media ${device.desktop} {
    align-items: center;
    margin-bottom: 50px;
    padding-left: ${props => (props.header ? "0" : "40px")};
  }

  @media ${device.large} {
    padding-left: 0;
  }
`

export const Title = styled.h2`
  font-size: 42px;
  color: ${props => props.theme.colors.black};
  line-height: 100%;
  text-transform: capitalize;
  margin: 0;

  @media ${device.tablet} {
    font-size: 48px;
  }

  @media ${device.large} {
    font-size: 64px;
  }
`

export const Secondary = styled(Title)`
  text-transform: none;

  color: ${props => props.theme.colors.grey[4]};

  @media ${device.tablet} {
    margin-left: 14px;
  }
`
