import { useStaticQuery, graphql } from "gatsby"

export const LatestPostsData = () => {
  const { wpgraphql } = useStaticQuery(
    graphql`
      query latestPosts {
        wpgraphql {
          posts(first: 18) {
            nodes {
              title
              colourFeatureImage
              link
              tagWord {
                tagWord
              }
              date
              featuredImage {
                sourceUrl
                srcSet
                mediaDetails {
                  width
                  height
                }
              }
              excerpt
              categories(first: 3) {
                nodes {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    `
  )

  return wpgraphql
}
