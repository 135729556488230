import React from "react"
import Menu from "./menu"
import Divider from "./divider"
import FooterMenu from "./footerMenu"

import { Container } from "./footerStyles"

const Footer = () => (
  <Container>
    <Menu />
    <Divider />
    <FooterMenu />
  </Container>
)
export default Footer
