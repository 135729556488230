export function getImageRatio(srcSet) {
	let ratio;

	if (!srcSet) {
		return 16 / 9;
	}

	if (srcSet.w_3000_width) {
		ratio = srcSet.w_3000_width / srcSet.w_3000_height;
	} else {
		ratio = srcSet['w_3000-width'] / srcSet['w_3000-height'];
	}

	return ratio;
}

export function checkSite(site, email) {
	const prefix1 = 'http://';


	if (
		site &&
		site.substr(0, prefix1.length) !== prefix1 &&
		(site.includes('www.') || site.includes('.com')) &&
		!site.includes('https') && !email
	) {
		return prefix1 + site;
	} else {
		return site;
	}
}

export function textTruncate(str, length, ending) {
	if (length == null) {
		length = 100;
	}

	if (ending == null) {
		ending = '...';
	}

	//remove https
	let processedStr = str.replace(/(^\w+:|^)\/\//, '');

	//remove  forward slash at end if exists
	if (str.substr(str.length - 1) === '/') {
		processedStr = processedStr.substring(0, processedStr.length - 1);
	}

	if (processedStr.length > length) {
		return processedStr.substring(0, length - ending.length) + ending;
	} else {
		return processedStr;
	}
}

export function kFormatter(num) {
	var total = (num / 1000).toFixed(1);

	// If number is 4.0 then just make it 4
	if (num > 999 && total.substring(total.length - 1) === '0') {
		total = total.substring(0, total.length - 2);
	}

	var nFormatted = num > 999 ? total + 'K' : num;

	return nFormatted;
}
