import React from "react"

import { GridContainer, EditorialSmallGridWrapper } from "./style.js"

const EditorialSmallGrid = ({ children, hide }) => {
  return (
    <GridContainer hide={hide}>
      <EditorialSmallGridWrapper>{children}</EditorialSmallGridWrapper>
    </GridContainer>
  )
}

export default EditorialSmallGrid
