import React, { useState, useEffect } from "react"
import {
  Logo,
  NavBarContainer,
  NavWrapper,
  Nav,
  NavList,
  QuickSearchTrigger,
} from "./style"
import NavItem from "./navItem"
import igLogo from "../../images/ig-logo.svg"
import searchIcon from "../../images/search-icon.svg"
import { MegaMenu } from "../MegaMenu"
import { Link } from "gatsby"
// import { SocialLinks } from '../SocialLinks';

const NavBar = ({ home, toggleSearchOpen, isSearchOpen }) => {
  const [isMenuShown, setMenuShown] = useState(false)

  return (
    <NavBarContainer home={home}>
      <Logo>
        <Link to="/">
          <img src={igLogo} alt="" />
        </Link>
      </Logo>
      <NavWrapper>
        <Nav>
          <NavList>
            <NavItem label="Design" path="/category/design/" />
            <NavItem label="Art" path="/category/art/" />
            <NavItem label="Photography" path="/category/photography/" />
            <NavItem label="Illustration" path="/category/illustration/" />
            <NavItem label="News & Resources" path="/editorial/" />
            <NavItem
              label="Advertising"
              path="/category/advertising/"
              no__desktop
            />
            <NavItem
              label="Architecture"
              path="/category/architecture/"
              no__desktop
            />
            <NavItem label="Branding" path="/category/identity/" no__desktop />
            <NavItem
              label="Fashion & Beauty"
              path="/category/fashion-2/"
              no__desktop
            />
            <NavItem
              label="Graphic Design"
              path="/category/design/"
              no__desktop
            />
            <NavItem
              label="Industrial Design"
              path="/category/industrial-design/"
              no__desktop
            />
            <NavItem
              label="Interior Design"
              path="/category/interiordesign/"
              no__desktop
            />
            <NavItem label="Logo Design" path="/category/logos/" no__desktop />
            <NavItem
              label="Packaging Design"
              path="/category/packaging/"
              no__desktop
            />
            <NavItem
              label="Pop Culture"
              path="/category/pop-culture/"
              no__desktop
            />
            <NavItem label="Print Design" path="/category/print/" no__desktop />
            <NavItem
              label="Product Design"
              path="/category/product-design/"
              no__desktop
            />
            <NavItem
              label="Technology"
              path="/category/technology-2/"
              no__desktop
            />
            <NavItem
              label="UX & UI Design"
              path="/category/ui-design/"
              no__desktop
            />
            <NavItem
              label="Vehicle Design"
              path="/category/automotive-2/"
              no__desktop
            />
            <NavItem
              label="Video & Motion"
              path="/category/video/"
              no__desktop
            />
          </NavList>
        </Nav>
        <QuickSearchTrigger isSearchOpen={isSearchOpen}>
          <Link to="/search/">
            <img src={searchIcon} alt="" />
          </Link>
        </QuickSearchTrigger>
        <MegaMenu
          isMenuShown={isMenuShown}
          isSearchOpen={isSearchOpen}
          setMenuShown={setMenuShown}
        />
      </NavWrapper>
    </NavBarContainer>
  )
}

export default NavBar
