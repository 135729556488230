import React from 'react';

import { SliderGridContainer, SliderGridWrapper } from './style.js';

const SliderGrid = ({editorial,children}) => {
	return (
		<SliderGridContainer>
			<SliderGridWrapper editorial={editorial}>
				{children}
			</SliderGridWrapper>
		</SliderGridContainer>
	);
};

export default SliderGrid;