import styled from "styled-components"

import { device } from "../../utils/breakpoints"

export const MenuList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, auto));
  grid-column-gap: 30px;
  list-style: none;
  margin: 0;
  margin-bottom: 10px;

  @media ${device.tablet} {
    grid-template-columns: repeat(auto-fit, minmax(155px, auto));
    grid-column-gap: 20px;
    grid-row-gap: 7px;
  }
  @media ${device.desktop} {
    grid-template-columns: repeat(6, minmax(120px, auto));
    grid-column-gap: 40px;
    grid-row-gap: 10px;
  }
`
