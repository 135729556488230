import { useStaticQuery, graphql } from "gatsby"

export const CategoriesData = () => {
  const { wpgraphql } = useStaticQuery(
    graphql`
      query Categories {
        wpgraphql {
          categories(first: 25) {
            nodes {
              name
              slug
              link
            }
          }
        }
      }
    `
  )

  return wpgraphql.categories.nodes;
}

