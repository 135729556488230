import React from "react"
import { ResponsiveGatsbyImage } from "../ResponsiveGatsbyImage"

import {
  FeaturedTeaserContainer,
  BackgroundColor,
  FeaturedTeaserWrapper,
  ImageContainer,
  InfoContainer,
  Title,
  Excerpt,
  Categories,
} from "./style.js"
import { Link } from "gatsby"

const FeaturedTeaser = ({
  first,
  second,
  categories,
  title,
  excerpt,
  bgColor,
  img,
  link,
  color,
}) => {
  const rgb = color && JSON.parse(color).join()

  return (
    <FeaturedTeaserContainer
      className="teaser-container"
      first={first}
      second={second}
    >
      <BackgroundColor first={first} bgColor={bgColor}>
        {" "}
        {img && (
          <ResponsiveGatsbyImage
            loading="eager"
            noFade
            src={img.sourceUrl}
            srcSet={img.srcSet}
            sizes="(min-width: 1024px) 740px, 100vw"
            height={img.mediaDetails.height}
            width={img.mediaDetails.width}
          />
        )}
      </BackgroundColor>
      <FeaturedTeaserWrapper className="teaser-wrapper" first={first}>
        <Link to={link}>
          <ImageContainer className="image-container" first={first}>
            {img && (
              <ResponsiveGatsbyImage
                loading="eager"
                noFade
                src={img.sourceUrl}
                srcSet={img.srcSet}
                sizes="(min-width: 1024px) 740px, 100vw"
                height={img.mediaDetails.height}
                width={img.mediaDetails.width}
                style={{ backgroundColor: color ? `rgb(${rgb})` : null }}
              />
            )}
          </ImageContainer>
        </Link>

        <InfoContainer first={first} className="info-container">
          <Categories>
            {categories &&
              categories
                .slice(0, 3)
                .map((category, index) => (
                  <Link
                    key={index}
                    to={`/category/${category.slug}/`}
                    dangerouslySetInnerHTML={{ __html: category.name }}
                  />
                ))}
          </Categories>
          <Title
            className="title"
            dangerouslySetInnerHTML={{ __html: title }}
          />

          <Excerpt
            className="excerpt"
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />
        </InfoContainer>
      </FeaturedTeaserWrapper>
    </FeaturedTeaserContainer>
  )
}

export default FeaturedTeaser
