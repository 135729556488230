import React from "react"
import { Link } from "gatsby"

import { ButtonContainer } from "./style.js"

const Button = ({ label, path, full }) => {
  return (
    <ButtonContainer full={full} className="button">
      <Link to={path ? path : "/"}>{label}</Link>
    </ButtonContainer>
  )
}

export default Button
