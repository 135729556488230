import React, { useState, useRef } from "react"
import {
  MegaMenuContainer,
  MegaMenuMain,
  More,
  MenuSectionContainer,
  MenuListing,
  MenuTitle,
  MenuSocialContainer,
  PopularSection,
  Overlay,
  CarouselWrapper,
  MegaMenuMainWrapper,
} from "./style"
import { useStaticQuery, graphql } from "gatsby"
import Hamburger from "./hamburger"
import MenuItem from "./menuItem"
import { SocialLinks } from "../SocialLinks"
import { Carousel } from "../Carousel"
import { CategoriesData } from "../../hooks/categories-data.js"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

const MegaMenu = ({ isSearchOpen, isMenuShown, setMenuShown }) => {
  const [isHamburgerClicked, setHamburgerClicked] = useState(false)
  const megaRef = useRef(null)

  const Pages = () => {
    const { wpgraphql } = useStaticQuery(
      graphql`
        query pagesData {
          wpgraphql {
            menus(where: { slug: "nav-pages" }) {
              nodes {
                name
                menuItems {
                  nodes {
                    label
                    url
                  }
                }
              }
            }
          }
        }
      `
    )

    return wpgraphql.menus.nodes
  }

  const GET_EDITORIALS = gql`
    query {
      editorials(first: 12) {
        nodes {
          title
          colourFeatureImage
          link
          featuredImage {
            sourceUrl
            srcSet
            mediaDetails {
              width
              height
            }
          }
        }
      }
    }
  `
  const { loading, error, data } = useQuery(GET_EDITORIALS, {
    ssr: false,
    skip: typeof window === 'undefined'
  })

  const handleLeave = e => {
    const element = e.toElement || e.relatedTarget
    if (megaRef.current.contains(element)) {
      return false
    }
    setMenuShown(false)
  }

  const carouselPosts = data && data.editorials.nodes
  const categories = CategoriesData()
  const pagesData = Pages()
  const pagesArray = pagesData[0].menuItems

  console.log("rendered")

  return (
    <MegaMenuContainer
      ref={megaRef}
      isMenuShown={isMenuShown}
      onMouseOver={() => setMenuShown(true)}
      onMouseOut={e => handleLeave(e)}
    >
      <Overlay
        isMenuShown={isMenuShown}
        isSearchOpen={isSearchOpen}
        isHamburgerClicked={isHamburgerClicked}
      />
      <Hamburger
        isSearchOpen={isSearchOpen}
        setHamburgerClicked={setHamburgerClicked}
        isHamburgerClicked={isHamburgerClicked}
      />
      <More isSearchOpen={isSearchOpen} isMenuShown={isMenuShown}>
        <span>More</span>
      </More>
      <MegaMenuMainWrapper
        className="mega"
        isMenuShown={isMenuShown}
        isHamburgerClicked={isHamburgerClicked}
      >
        <MegaMenuMain isMenuShown={isMenuShown}>
          <MenuSectionContainer>
            <MenuTitle>Categories</MenuTitle>
            <MenuListing>
              {categories.map((item, index) => (
                <MenuItem key={index} slug={item.slug} label={item.name} />
              ))}
            </MenuListing>
          </MenuSectionContainer>

          <MenuSectionContainer>
            <MenuTitle>Pages</MenuTitle>
            <MenuListing var>
              {pagesArray.nodes.map((item, index) => (
                <MenuItem
                  pages
                  key={index}
                  path={item.url}
                  label={item.label}
                />
              ))}
            </MenuListing>
          </MenuSectionContainer>

          <PopularSection>
            <CarouselWrapper>
              {carouselPosts && (
                <Carousel
                  cardsToShow={1}
                  header
                  title="News & Resources"
                  posts={carouselPosts}
                />
              )}
            </CarouselWrapper>
          </PopularSection>

          <MenuSocialContainer>
            <SocialLinks />
          </MenuSocialContainer>
        </MegaMenuMain>
      </MegaMenuMainWrapper>
    </MegaMenuContainer>
  )
}

export default MegaMenu
