/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"

import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import { theme } from "../utils/theme.js"
import { Header } from "./Header"
import { EmailSignup } from "./EmailSignup"
import { Footer } from "./Footer"

import "./layout.css"

import "../fonts/inspirationgrid/demo.css"

const Layout = ({
  noPage,
  title,
  category,
  home,
  description,
  children,
  search,
  article,
  noArticleHeaderImage,
  headerImage,
  adBelow,
  pinnedEditorial,
}) => (
  <>
    <div
      style={{
        margin: `0 auto`,
        paddingTop: 0,
      }}
    >
      <Helmet>
        <script async src="//www.instagram.com/embed.js"></script>
        <script
          async
          src="https://fundingchoicesmessages.google.com/i/pub-4461681428787239?ers=1"
          nonce="Ln6lpv-QPFaaZBGc8xmTCA"
        ></script>
        <script nonce="Ln6lpv-QPFaaZBGc8xmTCA">
          {`(function() {
            function signalGooglefcPresent() {
              if (!window.frames['googlefcPresent']) {
                if (document.body) {
                  const iframe = document.createElement('iframe');
                  iframe.style = 'width: 0; height: 0; border: none; z-index: -1000; left: -1000px; top: -1000px;';
                  iframe.style.display = 'none';
                  iframe.name = 'googlefcPresent';
                  document.body.appendChild(iframe);
                  } else {
                    setTimeout(signalGooglefcPresent, 0);
                  }
                }
              }
            signalGooglefcPresent();
          }
        )();
        `}
        </script>

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-9V6PKKERG6"
        ></script>

        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-9V6PKKERG6');
          `}
        </script>
      </Helmet>
      <ThemeProvider theme={theme}>
        <main>
          <Header
            adBelow={adBelow}
            title={title}
            description={description}
            noPage={noPage}
            category={category}
            home={home}
            search={search}
            article={article}
            noArticleHeaderImage={noArticleHeaderImage}
            headerImage={headerImage}
            pinnedEditorial={pinnedEditorial}
          />
          {children}
          <EmailSignup article={article || noPage} />
          <Footer />
        </main>
      </ThemeProvider>

      <footer></footer>
    </div>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
