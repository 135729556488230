import styled from "styled-components"
import { device } from "../../utils/breakpoints"

export const Container = styled.div`
  margin: 25px auto 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.tablet} {
    margin: 20px 0 50px 0;
  }

  a {
    display:block;
    transition: 120ms;
    svg {
      display:block;
    }
    
    :hover {
      opacity: 0.7;
    }
  }
`

export const Hr = styled.hr`
  width: calc(60% - 100px);
  height: 1px;
  background-color: ${props => props.theme.colors.zircon};
  margin-bottom: 0;
  margin-top: 10px;

  @media ${device.mobile} {
    width: calc(55% - 100px);
  }
`
