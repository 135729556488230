import React from 'react';
import { Clickable, HamburgerWrapper, FirstLine, SecondLine, ThirdLine } from './style.js';

const Hamburger = ({ isSearchOpen, setHamburgerClicked, isHamburgerClicked }) => {
	return (
		<Clickable isHamburgerClicked={isHamburgerClicked} isSearchOpen={isSearchOpen} onClick={()=>setHamburgerClicked(!isHamburgerClicked)}>
			<HamburgerWrapper>
				<FirstLine isHamburgerClicked={isHamburgerClicked} />
				<SecondLine isHamburgerClicked={isHamburgerClicked} />
				<ThirdLine isHamburgerClicked={isHamburgerClicked} />
			</HamburgerWrapper>
		</Clickable>
	);
};

export default Hamburger;
