import React from 'react';
import { ReactComponent as Avenue } from '../../images/made-by-avenue.svg';

import { Container, FooterMenuIcons, Menu, MenuItems, MenuItemsA, Copyright, RightContainer } from './footerMenuStyles';

import { SocialLinks } from '../SocialLinks/index';

const d = new Date();
const year = d.getFullYear();

const Divider = () => (
	<Container>
		<FooterMenuIcons>
			<Menu>
				{/* <MenuItems to="/popular">Popular</MenuItems> */}
				<MenuItems to="/about">About</MenuItems>
				<MenuItems to="/advertise">Advertise</MenuItems>
				<MenuItemsA href="mailto:connect@theinspirationgrid.com">Get in touch</MenuItemsA>
				<MenuItems to="/privacy-policy-terms-of-use/">Privacy & Terms</MenuItems>
			</Menu>
			<RightContainer>
				<SocialLinks footer />
				<a className="avenue" target="_blank" rel="noopener noreferrer" href="https://avenue.design/">
					<Avenue />
				</a>
			</RightContainer>
		</FooterMenuIcons>
		<Copyright>
			© { year } Inspiration Grid, all rights reserved. Some of our posts may contain affiliate links to partner
			brands. We earn a small commission if you click the link and make a purchase. There is no extra cost to you,
			so it’s just a nice way to help support the site. All images, videos, and other content posted on the site
			is attributed to their creators and original sources. If you see something wrong here or you would like to
			have it removed, please
			<a href="mailto:connect@theinspirationgrid.com"> contact us</a>.
		</Copyright>
	</Container>
);
export default Divider;
