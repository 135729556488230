import React, { useState } from "react"
import { LatestPostsData } from "../../hooks/latestPosts"
import { ResponsiveGatsbyImage } from "../ResponsiveGatsbyImage"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import {
  HeaderContainer,
  FeaturedTeaserGrid,
  HeaderWrapper,
  Tag,
  HeaderSocialLinksWrapper,
  HeaderBackground,
  ErrorTitle,
} from "./style"
import { SocialLinks } from "../SocialLinks"
import { FeaturedTeaser } from "../FeaturedTeaser"
import { NavBar } from "../NavBar"
import CategoryText from "./CategoryText"

const Header = ({
  home,
  title,
  category,
  description,
  search,
  article,
  noArticleHeaderImage,
  headerImage,
  adBelow,
  noPage,
  pinnedEditorial,
}) => {
  const searchingMan = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "searching-man.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 95) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const [isSearchOpen, setSearchOpen] = useState(false)

  const { editorial, position } = pinnedEditorial || {}

  const featuredPost = LatestPostsData()
  const firstTeaser =
    position === "1" && editorial ? editorial : featuredPost?.posts?.nodes[0]
  const secondTeaser =
    position === "2" && editorial
      ? editorial
      : position === "1" && editorial
      ? featuredPost?.posts?.nodes[0]
      : featuredPost?.posts?.nodes[1]

  const toggleSearchOpen = () => {
    setSearchOpen(!isSearchOpen)
  }

  return (
    <>
      <HeaderContainer
        adBelow={adBelow}
        id="header"
        noArticleHeaderImage={noArticleHeaderImage}
        home={home}
        search={search}
        category={category}
        article={article}
        noPage={noPage}
      >
        {home && (
          <FeaturedTeaserGrid>
            <FeaturedTeaser
              first
              link={firstTeaser.link}
              img={firstTeaser?.featuredImage}
              categories={firstTeaser?.categories?.nodes}
              title={firstTeaser.title}
              excerpt={firstTeaser.excerpt}
              bgColor="turquoise"
              color={firstTeaser.colourFeatureImage}
            />
            <FeaturedTeaser
              second
              img={secondTeaser?.featuredImage}
              categories={secondTeaser?.categories?.nodes}
              title={secondTeaser.title}
              link={secondTeaser.link}
              excerpt={secondTeaser.excerpt}
              bgColor="teal"
              color={secondTeaser.colourFeatureImage}
            />
          </FeaturedTeaserGrid>
        )}

        {(noArticleHeaderImage || category || search || noPage) && (
          <HeaderBackground article={article}>
            {headerImage ? (
              <ResponsiveGatsbyImage
                loading="eager"
                noFade
                src={headerImage.sourceUrl}
                srcSet={headerImage.srcSet}
                sizes="100vw"
                height={headerImage.mediaDetails.height}
                width={headerImage.mediaDetails.width}
              />
            ) : (
              <img
                src="https://res.cloudinary.com/avenue/image/upload/v1565247779/inspiration-grid-placeholder_bq9mzo.jpg"
                alt=""
              />
            )}
          </HeaderBackground>
        )}

        <HeaderWrapper home={home} search={search} article={article}>
          <Tag>Daily design inspiration for creatives.</Tag>
          <HeaderSocialLinksWrapper>
            <SocialLinks />
          </HeaderSocialLinksWrapper>
          {/* <QuickSearch isSearchOpen={isSearchOpen} toggleSearchOpen={toggleSearchOpen} /> */}
          <NavBar
            home={home}
            isSearchOpen={isSearchOpen}
            toggleSearchOpen={toggleSearchOpen}
          />
          {category && <CategoryText title={title} description={description} />}
          {noPage && (
            <ErrorTitle>
              <Img
                fadeIn={false}
                loading="eager"
                className="searching-man"
                fluid={searchingMan.file.childImageSharp.fluid}
              />
              <h1>Uh, oh!</h1>
            </ErrorTitle>
          )}
        </HeaderWrapper>
      </HeaderContainer>
    </>
  )
}

export default Header
