import styled from 'styled-components';
import { device } from '../../utils/breakpoints';
export const ButtonContainer = styled.div`
	display: inline-flex;
	width: ${(props) => (props.full ? '100%' : 'auto')};
	background: ${(props) => props.theme.colors.pink};
	border-radius: 2px;
	font-weight: 600;
	font-size: 14px;
	padding: 0 18px;
	height: ${(props) => (props.full ? '40px' : '36px;')};

	:hover {
		background: #C8295F;
		/* border: ${(props) => `2px solid ${props.theme.colors.pink}`}; */
		transition: 70ms;
		/* a {
			color: ${(props) => props.theme.colors.pink};
		} */
	}

	a {
		text-decoration: none;
		color: white;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: ${(props) => (props.full ? 'flex-end' : 'center')};
	}

	@media ${device.tablet} {
		font-size: ${(props) => props.full && '18px'};
		height: ${(props) => props.full && '48px'};
	}

	@media ${device.desktop} {
		font-size: ${(props) => (props.full ? '18px' : '16px')};
		height: ${(props) => (props.full ? '48px' : '42px')};
	}
`;
