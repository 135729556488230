import React from "react"
import Img from "gatsby-image"

const ResponsiveGatsbyImage = props => {
  const aspectRatio = props.aspectRatio || props.width / props.height
  if (!props.srcSet || (props.src.includes(".gif") && !props.noAnimate)) {
    return (
      <img
        onClick={props.onClick}
        className="gif"
        src={props.src}
        alt=""
        loading="lazy"
        style={props.style}
      />
    )
  }

  return (
    <Img
      ref={props.setRef}
      onClick={props.onClick}
      className={props.className}
      loading={props.loading || "lazy"}
      fadeIn={props.noFade ? false : true}
      fluid={{
        aspectRatio: aspectRatio,
        sizes: props.sizes || "100vw",
        src: props.src,
        srcSet: props.srcSet,
      }}
      alt={props.alt}
      style={props.style}
    />
  )
}

export default ResponsiveGatsbyImage
