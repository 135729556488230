import styled from 'styled-components';
import { device } from '../../utils/breakpoints';

export const HeaderContainer = styled.div`
	position: relative;
	z-index: 10;
	width: 100%;
	height: ${(props) =>
		props.noArticleHeaderImage || props.search ? '64px' : props.article ? '310px' : props.noPage ? '250px' : null};
	@media ${device.tablet} {
		height: ${(props) => (props.noArticleHeaderImage || props.search ? '116px' : props.article ? '400px' : null)};
		position: ${(props) => (props.noArticleHeaderImage ? 'relative' : props.article ? 'absolute' : 'relative')};
		z-index: ${(props) => props.article && 1};
	}

	@media ${device.desktop} {
		margin-bottom: ${(props) => props.home && props.adBelow && '60px'};
		pointer-events: none;
		height: ${(props) =>
			props.noPage
				? '300px'
				: props.noArticleHeaderImage || props.search ? '154px' : props.article ? '454px' : null};
	}

	@media ${device.large} {
		margin-bottom: ${(props) => props.home && props.adBelow && '0px'};

		height: ${(props) =>
			props.noPage
				? '400px'
				: props.noArticleHeaderImage || props.search ? '168px' : props.article ? '536px' : null};
	}
`;

export const FeaturedTeaserGrid = styled.div`
	display: grid;
	position: relative;
	grid-template-columns: 1fr;
	overflow: hidden;
	z-index: -1;
	@media ${device.desktop} {
		grid-template-columns: 1fr 1fr;
	}

	::before {
		content: "";
		top: 544px;
		position: absolute;
		width: 60px;
		display: none;

		left: 50%;
		transform: translateX(-50%);
		height: 100px;
		z-index: 2;
		pointer-events: none;
		background: #fff;

		@media ${device.desktop} {
			display: block;
		}
		@media ${device.large} {
			top: 521px;
		}
	}
	::after {
		content: "";
		top: 544px;
		position: absolute;
		width: 1px;
		left: 50%;
		display: none;
		z-index: 2;
		pointer-events: none;
		transition: transform 500ms;
		background: #d7e1e2;
		height: 100%;
		max-height: 320px;

		@media ${device.desktop} {
			display: block;
		}

		@media ${device.large} {
			top: 521px;
		}
	}
`;

export const HeaderWrapper = styled.div`
	z-index: 1;
	position: ${(props) => (props.home || props.article ? 'absolute' : 'relative')};
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1320px;
	padding: 20px 20px 0px 20px;
	pointer-events: none;

	@media ${device.tablet} {
		position: ${(props) => (props.article ? 'relative' : null)};
		padding: 60px 43px 0px 43px;
	}
	@media ${device.desktop} {
		left: 50%;
		transform: translateX(-50%);
		padding: 70px 60px 0px 60px;
	}

	@media ${device.large} {
		padding: 90px 60px 0px 60px;
	}
`;

export const Tag = styled.p`
	display: none;
	font-weight: 600;
	font-size: 12px;
	color: #fff;
	margin: 0;
	position: absolute;
	top: 11px;
	pointer-events: all;

	@media ${device.tablet} {
		display: block;
	}

	@media ${device.large} {
		top: 18px;
	}
`;

export const HeaderSocialLinksWrapper = styled.div`
	position: absolute;
	display: none;
	top: 11px;
	right: 30px;
	pointer-events: all;

	@media ${device.tablet} {
		display: block;
	}

	@media ${device.desktop} {
		right: 47px;
	}

	@media ${device.large} {
		top: 16px;
	}

	a.twitter-logo {
		svg {
		  path {
			fill: white !important;
		  }
		}
	  }
`;

export const CategoryTextContainer = styled.div`
	margin-top: 50px;
	padding-bottom: 40px;
	align-items: end;
	pointer-events: all;

	@media ${device.desktop} {
		padding-bottom: 60px;
		margin-top: 90px;
	}

	@media ${device.large} {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: ${(props) => props.theme.largeGap};
	}
`;

export const CategoryTitleWrapper = styled.div`
	margin-bottom: 20px;

	@media ${device.large} {
		margin-bottom: 0px;
		grid-column: span 2;
	}
	h1,
	h2 {
		font-size: 42px;
		line-height: 100%;
		margin: 0;
		color: white;

		@media ${device.desktop} {
			font-size: 96px;
		}
	}

	h2 {
		opacity: 0.5;
		z-index: -1;
	}
`;

export const CategoryExcerpt = styled.p`
	font-size: 14px;
	color: #fff;
	margin: 0;

	@media ${device.desktop} {
		font-size: 16px;
	}

	@media ${device.large} {
		margin-bottom: 17px;
	}
`;

export const HeaderBackground = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	object-fit: cover;
	overflow: hidden;
	height: 100%;
	z-index: 0;

	@media ${device.tablet} {
		display: block;
	}

	::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background: rgba(0, 0, 0, 0.2);
		height: 100%;
		z-index: 2;
		z-index: 0;
	}

	> img,
	.gatsby-image-wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		margin: 0;
		object-fit: cover;
		transform: scale(1.4);
		-webkit-transform: scale(1.4);

		filter: ${(props) => (props.article ? 'none' : 'blur(12px)')};

		@media ${device.tablet} {
			filter: blur(12px);
		}
	}
`;

export const ErrorTitle = styled.div`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 20px;
	width: calc(100% - 40px);
	/* margin-right:20px; */
	max-width: 1200px;

	h1 {
		font-size: 45px;
		line-height: 100%;
		margin: 0;
		color: white;

		@media ${device.mobile} {
			font-size: 60px;
		}

		@media ${device.tablet} {
			font-size: 75px;
		}

		@media ${device.desktop} {
			font-size: 96px;
		}
	}

	@media ${device.tablet} {
		width: calc(100% - 80px);
	}

	@media ${device.desktop} {
		width: calc(100% - 120px);
	}

	@media ${device.large} {
		bottom: 75px;
	}

	.searching-man {
		position: absolute !important;
		right: -5px;
		top: -90px;
		width: 135px;

		@media ${device.mobile} {
			top: -70px;
			right: 30px;
		}

		@media ${device.tablet} {
			/* right: 80px; */
			right: 0;
			top: -10px;
			width: 210px;
		}

		@media ${device.desktop} {
			right: 100px;
			top: 0px;
		}
		@media ${device.large} {
			width: 322px;
			right: 0px;
			top: -30px;
		}
	}
`;

export const ErrorGreetings = styled.div`
	width: calc(100% - 40px);
	max-width: 1200px;
	margin: 0 auto;
	/* z-index: 100; */
	position: relative;
	margin-bottom: 40px;

	h3 {
		font-size: 30px;
	}

	p {
		font-size: 16px;
	}

	@media ${device.tablet} {
		width: calc(100% - 80px);
		margin-bottom: 50px;

		p {
			max-width: 56%;
		}
	}
	@media ${device.large} {
		padding: 0;
		margin-bottom: 70px;

		h3 {
			font-size: 48px;
		}

		p {
			font-size: 20px;
		}
	}

	.searching-man {
		position: absolute !important;
		right: 0;
		top: -190px;
		width: 140px;
		@media ${device.tablet} {
			width: 220px;
		}
		@media ${device.large} {
			top: -280px;
			width: 322px;
		}
	}
`;
