import React from 'react';

import { MenuList } from './menuStyles';
import MenuItem from '../MegaMenu/menuItem';
import { CategoriesData } from '../../hooks/categories-data.js';

const Menu = () => {
	const categories = CategoriesData();

	return (
		<MenuList>
			{categories.map((item, index) => <MenuItem label={item.name} slug={item.slug} key={index} footer />)}
		</MenuList>
	);
};

export default Menu;
