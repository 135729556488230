import React from 'react';
import { MenuListItem } from './style';
import { Link } from 'gatsby';
import AvenueLink from '../AvenueLink.js';

const MenuItem = ({ label, slug, footer, pages, path }) => {


	return (
		<MenuListItem footer={footer}>
			{ pages ? (
				<AvenueLink to={path} dangerouslySetInnerHTML={{ __html: label }} />
			) : (
				<Link to={`/category/${slug}/`} dangerouslySetInnerHTML={{ __html: label }} />
			)}
		</MenuListItem>
	);
};

export default MenuItem;
