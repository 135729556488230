import styled from "styled-components"

import { device } from "../../utils/breakpoints"

export const NavBarContainer = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  position: relative;
  align-items: center;
  pointer-events: all;

  ::before {
    content: "";
    display: ${props => (props.home ? "none" : "block")};
    position: absolute;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.4);
    top: -21px;
    left: 0;

    @media ${device.desktop} {
      top: -30px;
    }

    @media ${device.large} {
      top: -44px;
    }
  }
`

export const Logo = styled.div`
  img {
    margin: 0;
    display: block;
    height: 26px;

    @media ${device.tablet} {
      height: 36px;
    }

    :hover {
      opacity: 0.7;
      transition: 100ms;
    }
  }
`

export const NavWrapper = styled.div`
  display: flex;
`

export const Nav = styled.nav`
  position: fixed;
  display: flex;
  /* justify-content:space-evenly; */
  bottom: 0;
  left: 0;
  margin: 0;
  background-color: ${props => props.theme.colors.black};
  overflow: auto;
  height: 60px;
  width: 100%;
  -webkit-overflow-scrolling: touch;

  /* z-index: 100; */

  ::-webkit-scrollbar {
    width: 10px !important;
    /* background: transparent !important; */
    height: 10px !important;
    display: none !important;
  }

  @media ${device.desktop} {
    background-color: transparent;
    opacity: 1;
    position: relative;
    bottom: auto;
    transform: none;
    justify-content: flex-end;
    height: 50px;
  }
`

export const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  overflow: visible;
  padding-inline-start: 0px;
  height: auto;
	background:none;
  align-items: center;
  justify-content: center;

  @media (min-width: 422px) {
    width: 100%;
    justify-content: space-around;
  }
`

export const NavListItem = styled.li`
  margin: 0;
  @media ${device.desktop} {
    display: ${props => (props.no__desktop ? "none" : "block")};
  }
  a {
    margin: 0;
    color: white;
    display: block;
    padding: 5px 17px;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;

    @media ${device.desktop} {
      font-size: 16px;
      padding: 10px 17px 10px 17px;
    }

    @media ${device.large} {
      padding: 10px 24px 10px 24px;
    }
  }

  span.label {
    position: relative;
    ::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0;
      transform: translateY(6px);
      transition: 230ms;
      height: 2px;
      width: 100%;
      background: #fff;
    }
  }

  &:hover {
    span.label::after {
      opacity: 1;
      transform: translateY(4px);
    }
  }
`

export const QuickSearchTrigger = styled.div`
  background: none;
  outline: none;
  border: none;
  padding: 0;
  z-index: ${props => props.isSearchOpen && 3};
  flex-shrink: 0;
  margin-right: 30px;
  cursor: pointer;
  opacity: 1;
  position: relative;
  pointer-events: none;

  a {
    width: 100%;
    height: 100%;
    pointer-events: all;
    display: flex;
    align-items: center;
    position: relative;

    ::before {
      content: "";
      position: absolute;
      height: 50px;
      width: 50px;
      transform: translateX(-50%) translateY(-50%);
      top: 50%;
      left: 50%;
    }

    cursor: pointer;

    :hover {
      img {
        opacity: 0.7;
        transition: 70ms;
      }
    }
  }

  @media ${device.desktop} {
    margin-left: 50px;
    margin-right: 0;

    ::before {
      content: "";
      position: absolute;
      width: 1px;
      top: 11px;
      left: -32px;
      background: rgba(255, 255, 255, 0.4);
      height: 28px;
    }
    order: 3;
  }

  @media ${device.large} {
    margin-left: 68px;

    ::before {
      left: -50px;
    }
  }

  img {
    width: 16px;
    height: 16px;
    display: block;
    margin: 0;

    @media ${device.tablet} {
      height: 23px;
      width: 23px;
    }
  }
`

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  opacity: ${props => (props.isMenuShown ? "0.5" : "0")};
  pointer-events: none;
`

export const NewTag = styled.span`
  position: absolute !important;
  top: -10px;
  left: 0;
  color: white;
  font-weight: 800;
  font-family: "AvenirNext";
  font-size: 8px;
`
