import React from 'react';

import { GridContainer, StandardGridWrapper } from './style.js';

const StandardGrid = ({children}) => {
	return (
		<GridContainer>
			<StandardGridWrapper>
				{children}
			</StandardGridWrapper>
		</GridContainer>
	);
};

export default StandardGrid;