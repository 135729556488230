import React from "react"
import { ResultsGridContainer, ErrorGreetings } from "./style.js"
import { Teaser } from "../Teaser"
import { StandardGrid, SmallGrid } from "./index.js"
import { GoogleAd } from "../GoogleAd"
import { AdvertisementWrapper } from "../AdvertisementWrapper"

const ResultsGrid = props => {
  const firstPosts = props.posts.slice(0, 6)
  const secondPosts = props.posts.slice(6, 30)

  return (
    <ResultsGridContainer search={props.search}>
      {props.noPage && (
        <ErrorGreetings>
          <h3>Can't find what you're looking for?</h3>
          <p>
            Maybe this page was moved or the content’s deleted. It doesn’t
            matter. Here’s some inspiration to keep you going.
          </p>
        </ErrorGreetings>
      )}
      <StandardGrid>
        {firstPosts.map((teaser, index) => {
          return (
            <Teaser
              key={index}
              title={teaser.title}
              excerpt={teaser.excerpt}
              color={teaser.colourFeatureImage}
              categories={props.posts && teaser.categories.nodes}
              link={teaser.link}
              img={teaser.image || teaser.featuredImage}
            />
          )
        })}
      </StandardGrid>
      {props.noPage ? null : (
        <>
          <AdvertisementWrapper horizontal>
            <GoogleAd
              className="horizontal"
              client="ca-pub-4461681428787239"
              slots={["2530522311", "9752087540", "4016545130"]}
            />
          </AdvertisementWrapper>

          <SmallGrid>
            {secondPosts.map((teaser, index) => {
              return (
                <Teaser
                  key={index}
                  small
                  color={teaser.colourFeatureImage}
                  title={teaser.title}
                  link={teaser.link}
                  img={teaser.image || teaser.featuredImage}
                />
              )
            })}
          </SmallGrid>
        </>
      )}
    </ResultsGridContainer>
  )
}

export default ResultsGrid
