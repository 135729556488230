import styled from "styled-components"

export const AdImage = styled.a`
  display: block;

  img {
    display: block;
    margin-bottom: 0;
  }

  img[src=""] {
    display: none;
  }
`
