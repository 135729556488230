import React from 'react';

import { SliderGridContainer, SliderGridWrapper } from './style.js';

const ArticleSliderGrid = ({ title, children, editorialPost }) => {
	return (
		<SliderGridContainer article>
			<h3>{title}</h3>
			<SliderGridWrapper editorialPost={editorialPost} article>
				{children}
			</SliderGridWrapper>
		</SliderGridContainer>
	);
};

export default ArticleSliderGrid;
