import React from 'react';
import { EmailContainer, HeadingContainer, EmailWrapper, Artist, BgImage, PrivacyText } from './style.js';
import { SignUp } from '../SignUp';
import { useStaticQuery, graphql, Link } from 'gatsby';

const EmailSignup = ({ article }) => {
	const data = useStaticQuery(graphql`
		{
			file(relativePath: { eq: "bg-inbox.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 3000, quality: 95) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<EmailContainer article={article}>
			<EmailWrapper article={article}>
				<HeadingContainer article={article}>
					<h2>
						Inspiration <br /> in your inbox
					</h2>
					<h4>Amazing art & design, never any spam.</h4>
				</HeadingContainer>
				<SignUp white article={article} />
			<PrivacyText  article={article} >We care about protecting your data. Please refer to our <Link to="/privacy-policy-terms-of-use/">Privacy Policy</Link> for more.</PrivacyText>

			</EmailWrapper>
			<Artist>
				Artwork: <Link to="/surreal-dreamscapes-illustrations-by-victor-mosquera">Victor Mosquera</Link>
			</Artist>
			<BgImage fluid={data.file.childImageSharp.fluid} />
		</EmailContainer>
	);
};

export default EmailSignup;
