import styled from "styled-components"
import { device } from "../../utils/breakpoints"

export const AdvertisementContainer = styled.div`
  display:none;
  margin: 0 auto;
  margin-bottom: ${props => props.homeSquare && "40px"};
  margin-top: ${props => props.horizontal && "40px"};
  max-width: ${props => props.theme.mainMaxWidth};
  background: ${props => props.theme.colors.grey[0]};
  place-items: center;
  order: ${props => props.order || null};
  position:relative;

  @media (min-width: 375px) {
    display: ${props =>
      props.desktop ||
      props.editorialVertical ||
      props.editorialSquare ||
      props.double ||
      props.articleVertical
        ? "none"
        : "grid"};
    width: ${props =>
      props.articleSquare ||
      props.articleHorizontal ||
      props.double ||
      props.square ||
      props.horizontal
        ? "335px"
        : null};
    height: ${props =>
      props.articleSquare ||
      props.double ||
      props.square ||
      props.articleHorizontal ||
      props.horizontal
        ? "285px"
        : null};
  }

  @media ${device.tablet} {
    margin: 0 auto;
    width: ${props =>
      props.articleHorizontal ? "335px" : props.horizontal ? "728px" : null};
    height: ${props =>
      props.articleHorizontal ? "285px" : props.horizontal ? "90px" : null};

    background: ${props =>
      props.articleHorizontal
        ? props.theme.colors.grey[0]
        : props.horizontal
        ? "none"
        : props.theme.colors.grey[0]};
    display: ${props =>
      props.editorialVertical || props.editorialSquare || props.square
        ? "none"
        : props.desktop || props.double
        ? "grid"
        : null};
    justify-content: ${props => (props.double ? "center" : null)};
    margin-bottom: ${props => props.articleSquare && "40px"};
    margin-top: ${props => props.horizontal && "60px"};

  }

  @media ${device.desktop} {
   
    width: ${props =>
      props.editorialVertical || props.articleSquare || props.editorialSquare
        ? "100%"
        : props.articleHorizontal
        ? "335px"
        : props.double
        ? "300px"
        : props.horizontal
        ? "940px"
        : null};
    height: ${props =>
      props.articleVertical
        ? "660px"
        : props.editorialVertical
        ? "660px"
        : props.editorialSquare
        ? "310px"
        : props.articleSquare
        ? "285px"
        : props.articleHorizontal
        ? "285px"
        : props.double
        ? "250px"
        : props.horizontal
        ? "170px"
        : null};
    background: ${props =>
      props.horizontal ? props.theme.colors.grey[0] : null};
    justify-content: center;
    margin-bottom: ${props => props.articleSquare && "43px"};
    display: ${props =>
      props.hide
        ? "none"
        : (props.desktop ||
            props.editorialVertical ||
            props.editorialSquare ||
            props.articleVertical ||
            props.desktop) &&
          "grid"};
      
    grid-column: ${props =>
      (props.editorialVertical || props.editorialSquare) && "span 1 / 4 "};
    grid-row: ${props =>
      props.editorialVertical
        ? "span 6 / 1"
        : props.editorialSquare
        ? "span 3 / 1"
        : null};
    /* grid-column: ${props => props.editorialSquare && "span 1 / 4 "};
    grid-row: ${props => props.editorialSquare && "span 3 / 1 "}; */



  }

  @media ${device.large} {
    width: ${props =>
      props.articleSquare
        ? "100%"
        : props.articleHorizontal
        ? "783px"
        : props.double
        ? "100%"
        : props.horizontal
        ? "1200px"
        : null};
    height: ${props =>
      props.articleVertical
        ? "660px"
        : props.articleSquare
        ? "310px"
        : props.articleHorizontal
        ? "150px"
        : props.double
        ? "945px"
        : null};

    background: ${props =>
      props.double ? props.theme.colors.grey[0] : props.theme.colors.grey[0]};
    align-content: space-evenly;
    grid-row: ${props => props.double && "span 2"};
    margin-bottom: ${props => props.articleSquare && "106px"};
  }

  >ins, a {
    
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    img {
      width: 100%;
      height:100%;
    }
    &.horizontal {
      width: 300px;
      height: 250px !important;

      @media ${device.tablet} {
        width: 728px;
        height: 90px !important;
      }

      @media ${device.large} {
        width: 970px;
        height: 90px !important;
      }
    }

    &.articleHorizontal {
      width: 300px;
      height: 250px !important;

      @media ${device.large} {
        width: 728px;
        height: 90px !important;
      }
    }

    &.articleVertical {
      width: 300px;
      height: 600px;
    }

    &.square {
      width: 300px;
      height: 250px;

      @media ${device.large} {

      top: ${props => props.double && "auto"};
      bottom: ${props => props.double && "30px"};
      transform: ${props => props.double && "translate3d(-50%, 0%, 0)"};

      }
 
    }

    &.vertical {
      top: 30px;
      left: 50%;
      transform: translate3d(-50%, 0%, 0);
      width: 300px;
      height: 600px;

      @media ${device.tablet} {
        display: none !important;
      }
      @media ${device.large} {
        display: block !important;
      }
    }
  }
`

export const DummyAd = styled.div`
  width: ${props =>
    props.horizontal
      ? "300px"
      : props.square
      ? " 300px"
      : props.vertical
      ? "300px"
      : null};
  height: ${props =>
    props.horizontal || props.square
      ? "250px"
      : props.vertical
      ? "600px"
      : null};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background: teal;
  display: ${props => (props.vertical ? "none" : null)};

  @media ${device.tablet} {
    width: ${props => (props.horizontal ? "728px" : null)};
    height: ${props => (props.horizontal ? "90px" : null)};
  }

  @media ${device.large} {
    width: ${props => (props.horizontal ? "970px" : null)};
    height: ${props => (props.horizontal ? "90px" : null)};
    display: ${props => (props.vertical ? "block" : null)};
  }
`
