import React from 'react';
import { Link } from 'gatsby';
import { Container, Hr } from './dividerStyles';
import { ReactComponent as Logo } from '../../images/ig-footer-logo.svg';

const Divider = () => (
	<Container>
		<Hr />
		<Link to="/">
			<Logo />
		</Link>
		<Hr />
	</Container>
);
export default Divider;
