/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, image, lang, meta, title, url, home, category }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
		`
	);

	let googleTitle, shareTitle;

	if (home) {
		googleTitle = `${site.siteMetadata.description} | ${site.siteMetadata.title}`;
		shareTitle = googleTitle;
	} else {
		googleTitle = `%s | ${site.siteMetadata.description} | ${site.siteMetadata.title}`;
		shareTitle = `${title} | ${site.siteMetadata.title}`;
	}



	const metaDescription = description || site.siteMetadata.description;

	const pageURL = url || process.env.GATSBY_WEBSITE_DOMAIN;

	const decodedTitle = title
		.replace('&#038;', '&')
		.replace('&#8211;', '-')
		.replace('&amp;', '&')
		.replace('&#8216;', "'")
		.replace('&#8217;',"'")
		.replace('&#8217;',"'")


	return (
		<Helmet
			htmlAttributes={{
				lang
			}}
			title={decodedTitle}
			titleTemplate={googleTitle}
			link={[ { rel: 'canonical', href: pageURL } ]}
			meta={[
				{
					name: `description`,
					content: metaDescription
				},
				{
					property: `og:title`,
					content: shareTitle
				},
				{
					property: `og:description`,
					content: metaDescription
				},
				{
					property: `og:type`,
					content: `website`
				},
				{
					property: `og:image`,
					content: image ? image.sourceUrl : 'https://inspgr.id/app/uploads/2011/02/about-feature-image.jpg'
				},
				{
					name: `twitter:card`,
					content: `summary_large_image`
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author
				},
				{
					name: `twitter:title`,
					content: shareTitle
				},
				{
					name: `twitter:description`,
					content: metaDescription
				}
			].concat(meta)}
		/>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired
};

export default SEO;
